import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const ExecutionLineChart = ({ data, type }) => {
  const sortedData = [...data].sort((a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf());

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={sortedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="created_at" tickFormatter={(tick) => moment.utc(tick).local().format('MM-DD-YYYY')} />
          <YAxis domain={['auto', 'dataMax']} allowDataOverflow={false} />
          <Tooltip labelFormatter={(label) => moment.utc(label).local().format('MM-DD-YYYY')} />
          <Legend />
          {(type === 'email' || type === 'advanced') && (
            <Line type="monotone" dataKey="count_email_success" stroke="#82ca9d" name="Email Success" />
          )}
          {(type === 'email' || type === 'advanced') && (
            <Line type="monotone" dataKey="count_email_fail" stroke="#ff0000" name="Email Fail" />
          )}
          {(type === 'sms' || type === 'advanced') && (
            <Line type="monotone" dataKey="count_sms_success" stroke="#8884d8" name="SMS Success" />
          )}
          {(type === 'sms' || type === 'advanced') && (
            <Line type="monotone" dataKey="count_sms_fail" stroke="#ff7300" name="SMS Fail" />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ExecutionLineChart;
