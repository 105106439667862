import React, { useMemo } from 'react';
import { NodeWrapper } from '.';
import { MessageOutlined } from '@ant-design/icons';
import { InputNumber, Select, Flex } from 'antd';
import { useCampaign, useUpdateNode } from '../hooks';

const defaultOptions = [
  { value: 'open-last-message', label: 'Open Last Message' },
  { value: 'successfully-receive-message', label: 'Successfully Receive Message' },
  { value: 'clicks-application-link', label: 'Click Application Link' },
  { value: 'completes-application', label: 'Completed Application' },
  { value: 'clicks-custom-link', label: 'Clicks Custom Link' },
  { value: 'unsubscribes', label: 'Unsubscribes' },
];

export const NodeCondition = ({ data, id, type, positionAbsoluteX, positionAbsoluteY }) => {
  const { isEditionDisabled } = useCampaign();
  const { onChangeNode, getSourceNodeType } = useUpdateNode();

  const sourceNode = getSourceNodeType(id);

  const options = useMemo(() => {
    if (sourceNode === 'smsNode') {
      // If source node is SMS, filter out the 'open-last-message' option
      return defaultOptions.filter((option) => option.value !== 'open-last-message');
    }

    return defaultOptions;
  }, [sourceNode]);

  useMemo(() => {
    // If source node is SMS and current action is 'open-last-message',
    if (sourceNode === 'smsNode' && data.userAction === 'open-last-message') {
      // change to a valid option (first available one)
      onChangeNode(id, 'userAction', options[0].value);
    }
  }, [sourceNode, data.userAction]);

  const handleTime = (value) => {
    onChangeNode(id, 'timeframe', value);
  };

  const handleAction = (value) => {
    onChangeNode(id, 'userAction', value);
  };

  const handleValidation = (value) => {
    onChangeNode(id, 'condition', value);
  };

  return (
    <NodeWrapper
      id={id}
      nodeType={type}
      isSourceAvailable
      isBranchable
      position={{ x: positionAbsoluteX, y: positionAbsoluteY }}>
      <NodeWrapper.Header id={id} Icon={MessageOutlined} deactiveEdit deactiveCopy>
        Condition
      </NodeWrapper.Header>
      <Flex vertical gap="middle">
        <div>
          <NodeWrapper.Label strong style={{ fontSize: '1.125rem' }}>
            If recipient
          </NodeWrapper.Label>
          <Flex gap="small">
            <Select
              className="nodrag"
              defaultValue="true"
              value={data.condition || 'true'}
              onChange={handleValidation}
              options={[
                { label: 'Does', value: 'true' },
                { label: 'Does Not', value: 'false' },
              ]}
              disabled={isEditionDisabled}
              style={{
                width: '40%',
              }}
              size="large"
            />
            <Select
              className="nodrag"
              defaultValue="open-last-message"
              value={data.userAction || ''}
              onChange={handleAction}
              options={options}
              disabled={isEditionDisabled}
              style={{
                width: '60%',
              }}
              size="large"
            />
          </Flex>
        </div>
        <div>
          <NodeWrapper.Label strong style={{ fontSize: '1.125rem' }}>
            Within (hours)
          </NodeWrapper.Label>
          <InputNumber
            className="nodrag"
            min={1}
            max={48}
            defaultValue={24}
            value={data.timeframe || 0}
            onChange={handleTime}
            disabled={isEditionDisabled}
            size="large"
            style={{
              width: '100%',
            }}
          />
        </div>
      </Flex>
    </NodeWrapper>
  );
};
