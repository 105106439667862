import React, { useMemo } from 'react';
import { NodeWrapper } from '.';
import { PlusOutlined } from '@ant-design/icons';
import { Select, Flex } from 'antd';
import { useCampaign, useUpdateNode } from '../hooks';
import { useReactFlow, Handle, Position, useNodes } from '@xyflow/react';
import styles from './node-trigger.module.scss';

const nodesContent = [
  { id: 'email', value: 'emailNode', label: 'Send Email' },
  { id: 'sms', value: 'smsNode', label: 'Send SMS' },
];
const nodesLogic = [
  { value: 'timerNode', label: 'Add Delay' },
  { value: 'conditionNode', label: 'Add Condition' },
  { value: 'endNode', label: 'End Flow' },
];

export const NodePlaceholder = ({ data, id, selected }) => {
  const { handleTailBranch, campaign, isEditionDisabled } = useCampaign();

  const nodeOptions = useMemo(() => {
    const campaignType = campaign.platform;
    if (campaignType === 'hybrid') return nodesContent;

    return nodesContent.filter((node) => campaignType === node.id);
  }, [campaign]);

  const { onChangeNode, handleNewNode } = useUpdateNode();
  const { setNodes } = useReactFlow();
  const nodes = useNodes();

  const convertToEnd = () => {
    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            type: 'endNode',
          };
        }
        return node;
      });
    });
    handleTailBranch({ placeholderId: id, status: 'endNode' });
  };

  const handleAction = (value) => {
    onChangeNode(id, 'nodeSelection', value);

    if (value === 'endNode') {
      convertToEnd();
    } else {
      handleNewNode(value, id);
    }
  };

  return (
    <NodeWrapper
      id={id}
      className={`${styles.nodePlaceholcer} ${selected && styles.selected}`}
      isSourceAvailable={false}>
      <Flex vertical gap="middle">
        <PlusOutlined className={styles.icon} />
        <div>
          <Select
            className="nodrag"
            placeholder="Select a node type"
            onChange={handleAction}
            options={[...nodeOptions, ...(nodes.length > 2 ? nodesLogic : [])]}
            style={{
              width: '100%',
            }}
            size="large"
            disabled={isEditionDisabled}
          />
        </div>
      </Flex>
      <Handle type="target" style={{ visibility: 'hidden' }} position={Position.Top} isConnectable={false} />
      <Handle type="source" style={{ visibility: 'hidden' }} position={Position.Bottom} isConnectable={false} />
    </NodeWrapper>
  );
};
