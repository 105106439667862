import { useCallback } from 'react';
import { useReactFlow, useNodes, useEdges } from '@xyflow/react';
import { useCampaign, getGlobalParams } from '.';

export const useUpdateNode = () => {
  const { campaign, handleTailBranch } = useCampaign();
  const { setNodes, getNodes, setEdges, fitBounds } = useReactFlow();

  const nodes = useNodes();
  const edges = useEdges();

  const onChangeNode = (nodeId, key, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          // it's important to create a new node object
          return {
            ...node,
            data: {
              ...node.data,
              [key]: value,
            },
          };
        }

        return node;
      })
    );
  };

  const handleNodeParam = ({ nodeId, key, value }) => {
    setNodes((nde) =>
      nde.map((node) => {
        if (node.id === nodeId) {
          // Find the item in the data array that matches the key
          let keyFound = false;
          const updatedData = node.data.map((item) => {
            if (item.key === key) {
              keyFound = true;
              return { ...item, value };
            }
            return item;
          });

          // If the key was not found, add a new { key, value } object to the data array
          if (!keyFound) {
            updatedData.push({ key, value });
          }

          return {
            ...node,
            data: updatedData,
          };
        }
        return node;
      })
    );
  };

  const removeNodeParam = ({ nodeId, key }) => {
    setNodes((nde) =>
      nde.map((node) => {
        if (node.id === nodeId) {
          // Filter out the item in the data array that matches the key
          const updatedData = node.data.filter((item) => item.key !== key);

          return {
            ...node,
            data: updatedData,
          };
        }
        return node;
      })
    );
  };

  const updateNodeData = (nodeId, newData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: newData,
          };
        }
        return node;
      })
    );
  };

  const globalParams = getGlobalParams(campaign.params);

  const addNewNode = ({ type, position, adjustLayout }) => {
    const newNodeId = `${type}-${getNodes().length + 1}`;

    const newNodeData = getNewNode(type, globalParams);

    const newNode = {
      id: newNodeId,
      type,
      position,
      data: newNodeData,
    };

    setNodes((nds) => {
      if (adjustLayout) {
        setTimeout(() => {
          fitBounds(
            {
              x: position.x,
              y: position.y,
              width: 400,
              height: 350,
            },
            {
              padding: 0.5,
              duration: 800,
            }
          );
        }, 50);
      }
      return [...nds, newNode];
    });

    return newNodeId;
  };

  const handleNewNode = (nodeSelection, id, adjustLayout = true) => {
    const placeholder = nodes.find((node) => node.id === id);

    const connectedToThisNode = edges.find((edge) => edge.target === id);

    const newNodePosition = { ...placeholder.position };

    const newNodeId = addNewNode({ type: nodeSelection, position: newNodePosition, adjustLayout: true });

    setEdges((edges) => {
      const connectToNewNode = {
        id: `${connectedToThisNode.source}-${newNodeId}`,
        source: connectedToThisNode.source, // previous node before placeholder
        target: newNodeId, // new node added
      };
      const connectToPlaceholder = {
        id: `${newNodeId}-${id}`,
        animated: true,
        source: newNodeId,
        target: id,
      };

      // Remove the last connection targeting 'placeholder'
      const updatedEdges = edges.filter((edge) => edge.target !== connectedToThisNode.target);

      return [...updatedEdges, connectToNewNode, connectToPlaceholder];
    });

    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            position: { ...node.position, y: node.position.y + node.measured.height * 2.2 },
          };
        }
        return node;
      });
    });
  };

  const handleChangeToPlaceholder = (placeholderId) => {
    setNodes((nds) => {
      return nds.map((node) => {
        if (node.id === placeholderId) {
          return {
            ...node,
            type: 'placeholderNode',
          };
        }
        return node;
      });
    });

    handleTailBranch({ placeholderId, status: 'placeholderNode' });
  };

  const getSourceNodeType = useCallback(
    (targetNodeId) => {
      if (!targetNodeId) return null;

      // Find the edge that has the current node as target
      const incomingEdge = edges.find((edge) => edge.target === targetNodeId);
      if (!incomingEdge) return null;

      // Get the source node ID
      const sourceNodeId = incomingEdge.source;

      // Find the source node in the nodes list
      const sourceNode = nodes.find((node) => node.id === sourceNodeId);

      return sourceNode ? sourceNode.type : null;
    },
    [edges, nodes]
  );

  return {
    onChangeNode,
    handleNodeParam,
    removeNodeParam,
    updateNodeData,
    addNewNode,
    handleNewNode,
    handleChangeToPlaceholder,
    getSourceNodeType,
  };
};

export const getNewNode = (type, globalParams) => {
  let newNodeData;
  switch (type) {
    case 'emailNode':
      newNodeData = [
        ...globalParams,
        { key: '{{subject}}', value: '' },
        { key: '{{message_text}}', value: 'New message' },
        { key: '{{header_text}}', value: 'Start your message' },
        { key: '{{call_to_action_type}}', value: 'none' },
        { key: '{{signature_type}}', value: 'none' },
        { key: '{{signature_label}}', value: '' },
        { key: '{{signature_name}}', value: '' },
        {
          key: '{{email_image}}',
          value:
            'https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/imagen-custom-campaign.png',
        },
      ];
      break;
    case 'smsNode':
      newNodeData = [
        ...globalParams,
        { key: '{{message_text}}', value: 'Start your text' },
        { key: '{{sms_url_link}}', value: '' },
        { key: '{{signature_type}}', value: 'none' },
        { key: '{{signature_label}}', value: '' },
        { key: '{{signature_name}}', value: '' },
        {
          key: '{{email_image}}',
          value: '',
        },
      ];
      break;
    case 'timerNode':
      newNodeData = {
        type: 'timerNode',
        time: 8,
        timeframe: 'hours',
      };
      break;
    case 'conditionNode':
      newNodeData = {
        type: 'conditionNode',
        userAction: 'open-last-message',
        condition: 'true',
        timeframe: 24,
      };
      break;
    case 'placeholderNode':
      newNodeData = {
        type: 'placeholderNode',
        nodeSelection: '',
      };
      break;
    default:
      return;
  }

  return newNodeData;
};
