import React, { useEffect, useMemo, useState } from 'react';
import {
  _getIntegrationCampus,
  _getIntegrationStatus,
  _getLeadSources,
  _getLeadYearOptions,
} from 'controllers/leads/leads_controller';
import { useSegmentationCount } from './use-segmentation-count';
import { FilterValue } from './filter-value';
import { PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { singleValues, useOptions } from './utils-options';
import { Button } from 'components/pages/communication-hub/components';
import styles from './filter-value.module.scss';

export const mapSegmentationValues = (values) => {
  const mappedValues = values?.map((filter) => {
    const [field, value] = filter.split(':');

    let values;
    if (!singleValues.includes(field)) {
      values = value.split(',');
      if (field === 'lead_source_id' || field === 'lead_status_id') {
        values = values.map(Number);
      }
    } else {
      values = Boolean(value);
    }

    return { field, values };
  });
  return mappedValues;
};

export const ToolSegmentation = ({
  school,
  handleSegmentationValue,
  leadCountForMarketingCampaigns,
  leadStatuses,
  savedParams = [],
  audienceLanguage,
  onChangeValueLegagy,
  prevSentToValue,
  prevLeadStatusId,
  isAdvancedCampaign,
}) => {
  const { fields, options } = useOptions(school, leadStatuses);

  const [filteringParams, setFilteringParams] = useState([
    {
      field: 'status',
      values: [],
    },
  ]);

  useEffect(() => {
    // initial update if there is filters saved
    if (savedParams.length > 0) {
      const mapped = mapSegmentationValues(savedParams);

      setFilteringParams(mapped);
    } else if (prevSentToValue || prevLeadStatusId) {
      if (prevSentToValue) {
        const allSelections = options.status.map((item) => item.id);
        setFilteringParams([
          { field: 'status', values: prevSentToValue === 'all' ? allSelections : [prevSentToValue] },
        ]);
      }
      if (prevLeadStatusId) {
        const allSelections = options.lead_status_id.map((item) => item.id);
        setFilteringParams((prevState) => [
          ...prevState,
          { field: 'lead_status_id', values: [-1, -2].includes(prevLeadStatusId) ? allSelections : [prevLeadStatusId] },
        ]);
      }
    }
  }, []);

  // update main params state
  useEffect(() => {
    // handleSegmentationValue update the global campaign config object
    handleSegmentationValue(filteringParams);
  }, [filteringParams]);

  const addNewParam = () => {
    setFilteringParams((prevState) => [
      ...prevState,
      {
        field: '',
        values: null,
      },
    ]);
  };

  const handleFilter = (index, { value }) => {
    let updatedObj = {
      ...filteringParams[index],
      field: value,
    };

    if (filteringParams[index].field !== value) {
      updatedObj.values = [];
    }

    if (singleValues.includes(value)) {
      updatedObj.values = true;
    }

    updateFilteringParams(index, updatedObj);
  };

  const handleValues = (index, { value }) => {
    const isBoolean = typeof value === 'boolean';

    if (isBoolean) {
      const updatedObj = {
        ...filteringParams[index],
        values: value,
      };
      updateFilteringParams(index, updatedObj);
    } else {
      const isContaining = filteringParams[index].values.includes(value);

      const updatedObj = {
        ...filteringParams[index],
        values: isContaining
          ? filteringParams[index].values.filter((item) => item !== value)
          : [...filteringParams[index].values, value],
      };
      updateFilteringParams(index, updatedObj);

      if (filteringParams[index].field === 'status' || filteringParams[index].field === 'lead_status_id') {
        onChangeValueLegagy(filteringParams[index].field, updatedObj.values.join(','));
      }
    }
  };

  const onSelectAll = (index, field) => {
    const allSelections = options[field].map((item) => item.id);
    const updatedObj = {
      ...filteringParams[index],
      values: allSelections,
    };
    updateFilteringParams(index, updatedObj);
  };

  const updateFilteringParams = (index, updatedObj) => {
    setFilteringParams((prevState) => {
      const updatedParams = [...prevState];
      updatedParams[index] = updatedObj;
      return updatedParams;
    });
  };

  const removeFilter = (field) => {
    if (field === 'status' || field === 'lead_status_id') onChangeValueLegagy(field, '');

    setFilteringParams((prevState) => prevState.filter((item) => item.field !== field));
  };

  const { count } = useSegmentationCount({
    leadCountForMarketingCampaigns,
    filteringParams,
    audienceLanguage,
  });

  const availableFields = useMemo(
    () => fields.filter((field) => !filteringParams.some((param) => param.field === field.id)),
    [fields, filteringParams]
  );

  const language = useMemo(() => {
    const labels = { all: 'ALL', en: 'ENGLISH', es: 'SPANISH' };
    return labels[audienceLanguage];
  }, [audienceLanguage]);

  return (
    <>
      {!isAdvancedCampaign && (
        <>
          <div className="toolSegmentation">
            <div className={styles.header}>
              <h4 className={styles.heading}>
                Total Audience Size: <span className={styles.count}>{count}</span>
              </h4>
            </div>
            <p className={styles.language}>Audience Language: {language}</p>
          </div>
        </>
      )}

      <div className={styles.container}>
        {filteringParams.map((filter, index) => (
          <FilterValue
            key={`filter ${index}`}
            fields={fields}
            idx={index}
            filter={filter}
            handleFilter={handleFilter}
            handleValues={handleValues}
            removeFilter={() => removeFilter(filter.field)}
            options={options[filter.field]}
            onSelectAll={() => onSelectAll(index, filter.field)}
            availableFields={availableFields}
            isAdvancedCampaign={isAdvancedCampaign}
          />
        ))}
      </div>
      <div className={styles.container}>
        <Button
          className={styles.addFilter}
          type="button"
          onClick={addNewParam}
          disabled={availableFields.length === 0}>
          <span className={styles.addFilter_layout}>
            <PlusCircleOutlined />
            <span style={{ marginLeft: '.5rem' }}>Add Filter</span>
          </span>
        </Button>
      </div>
      <div className={styles.notes}>
        {isAdvancedCampaign && count > 0 && (
          <div className={styles.banner}>
            <div className={styles.iconWrapper}>
              <UserOutlined className={styles.icon} />
            </div>
            <div className={styles.content}>
              <p className={styles.label}>Current Audience Size</p>
              <p className={styles.value}>
                {count}
                <span className={styles.qualifier}>qualified leads</span>
              </p>
            </div>
          </div>
        )}
        {count === 0 && (
          <p className={styles.caution}>
            Current audience size is 0 – your campaign will not be sent to anybody until audience size includes at least
            1 lead.
          </p>
        )}
        {filteringParams[0].values.includes('archived') && (
          <p>Messages sent to Archived Leads are limited to leads added within the past year</p>
        )}
      </div>
    </>
  );
};
