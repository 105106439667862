import { useEffect, useState, useMemo } from 'react';
import {
  _getIntegrationCampus,
  _getIntegrationStatus,
  _getLeadSources,
  _getLeadYearOptions,
} from 'controllers/leads/leads_controller';
import schoolGradesUtil from 'util/school-grades-util';
import leadStatus from '../../utils/CampaingsSentTo';

export const singleValues = ['application_received', 'enrollment_confirmed'];

export const useOptions = (school, leadStatuses) => {
  const [fields, setFields] = useState([
    { id: 'status', name: 'Stage' },
    { id: 'grade', name: 'Grade' },
    { id: 'year', name: 'Year' },
    { id: 'lead_source_id', name: 'Source' },
    { id: 'application_received', name: 'Application Received' },
    { id: 'enrollment_confirmed', name: 'Enrollment Confirmed' },
  ]);

  const grades = schoolGradesUtil.getGrades(school).map((grade) => ({
    id: grade.value,
    name: grade.label,
  }));

  const [intStatus, setIntStatus] = useState([]);
  const [intCampus, setIntCampus] = useState([]);
  const [years, setYears] = useState([]);
  const [substage, setSubstage] = useState([]);

  useMemo(() => {
    if (leadStatuses.length > 0) {
      setSubstage([...leadStatuses, { id: -1, name: 'No value' }]);
      setFields((prevState) => [...prevState, { id: 'lead_status_id', name: 'Sub-Stage' }]);
    }
  }, [leadStatuses]);

  const [leadSources, setSources] = useState([]);
  useEffect(() => {
    loadLeadSources();
    _getLeadYearOptions(school.id).then((data) => setYears([...data, { value: '', label: 'No value' }]));

    _getIntegrationStatus(school.id).then((data) => {
      if (data.length > 0) {
        setIntStatus(data);
        setFields((prevState) => [...prevState, { id: 'sync_intg_status', name: 'Integration Status' }]);
      }
    });
    _getIntegrationCampus(school.id).then((data) => {
      if (data.length > 0) {
        setFields((prevState) => [...prevState, { id: 'intg_campus', name: 'Integration Campus' }]);
        setIntCampus(data);
      }
    });
  }, []);

  const loadLeadSources = async () => {
    const res = await _getLeadSources(school.id);
    const sources = await res.json();

    // adding null to count leads with no lead_source_id
    const _sources = [...sources, { id: 0, name: 'No value' }];

    setSources(_sources);
  };

  const options = {
    status: leadStatus.map((status) => ({ id: status.value, name: status.label })),
    grade: grades,
    lead_source_id: leadSources,
    lead_status_id: substage,
    year: years.filter(({ value }) => value !== 'N/A').map((year) => ({ id: year.value, name: year.label })),
    application_received: [
      { id: true, name: 'True' },
      { id: false, name: 'False' },
    ],
    enrollment_confirmed: [
      { id: true, name: 'True' },
      { id: false, name: 'False' },
    ],
    sync_intg_status: intStatus,
    intg_campus: intCampus,
  };

  return { fields, options };
};
