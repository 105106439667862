import React from 'react';
import { NodeWrapper } from '.';
import { StopOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import styles from './node-trigger.module.scss';
import { useUpdateNode } from '../hooks';

export const NodeEnd = ({ id, selected }) => {
  const { handleChangeToPlaceholder } = useUpdateNode();

  return (
    <NodeWrapper className={`${styles.end} ${selected && styles.selected}`} isSourceAvailable={false}>
      <Typography.Text type="secondary">This is the end of the flow.</Typography.Text>
      <button onClick={() => handleChangeToPlaceholder(id)}>
        <StopOutlined />
      </button>
    </NodeWrapper>
  );
};
