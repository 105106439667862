import { useMemo, useState } from 'react';
import { Handle, Position, useReactFlow } from '@xyflow/react';
import { Flex, Typography, Button, Modal, Dropdown } from 'antd';
import { CopyOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ApartmentOutlined } from '@ant-design/icons';
import styles from '../flow-designer.module.scss';
import { onOpenEditModal } from 'redux/campaigns/campaigns-reducer';
import { useCampaign, useUpdateNode } from '../hooks';
import classNames from 'classnames';

export const NodeWrapper = ({
  id,
  nodeType,
  children,
  isFirstNode,
  className,
  isSourceAvailable = true,
  isBranchable = false,
  position,
}) => {
  const { setEdges, getEdges } = useReactFlow();
  const { addNewNode } = useUpdateNode();
  const { campaign, isEditionDisabled } = useCampaign();

  const { isEmail, isSMS } = useMemo(() => {
    const platform = campaign?.platform;
    return {
      isEmail: platform === 'email' || platform === 'hybrid',
      isSMS: platform === 'sms' || platform === 'hybrid',
    };
  }, [campaign]);

  const isContentNode = nodeType === 'emailNode' || nodeType === 'smsNode';

  const handleNewBranch = (type, isNewBranch) => {
    const newNodeId = addNewNode({
      type,
      position: {
        x: position.x + (isNewBranch ? 325 : 75),
        y: position.y + 175,
      },
      adjustLayout: true,
    });

    if (!isNewBranch) {
      const outgoingEdge = getEdges().find((edge) => edge.source === id);

      if (outgoingEdge) {
        const newEdge = {
          id: `${newNodeId}-${outgoingEdge.target}`,
          source: newNodeId,
          target: outgoingEdge.target,
        };
        setEdges((edges) => [...edges.filter((edge) => edge.id !== outgoingEdge.id), newEdge]);
      }
    }

    const connectToNewNode = {
      id: `${id}-${newNodeId}`,
      source: id,
      target: newNodeId,
    };
    setEdges((edges) => {
      return [...edges, connectToNewNode];
    });
  };

  const items = [
    ...(isContentNode
      ? [
          {
            key: 'branch-timerNode',
            label: (
              <button className={styles.addButton} onClick={() => handleNewBranch('timerNode', true)}>
                <ApartmentOutlined style={{ color: '#0099cc' }} />
                Start New Branch - Timer
              </button>
            ),
          },
          {
            key: 'branch-conditionNode',
            label: (
              <button className={styles.addButton} onClick={() => handleNewBranch('conditionNode', true)}>
                <ApartmentOutlined style={{ color: '#0099cc' }} />
                Start New Branch - Condition
              </button>
            ),
          },
          {
            type: 'divider',
          },
        ]
      : []),
    ...(isEmail
      ? [
          {
            key: 'emailNode',
            label: (
              <button className={styles.addButton} onClick={() => handleNewBranch('emailNode')}>
                <PlusOutlined style={{ color: '#0099cc' }} />
                Insert Email Node Below
              </button>
            ),
          },
        ]
      : []),
    ...(isSMS
      ? [
          {
            key: 'smsNode',
            label: (
              <button className={styles.addButton} onClick={() => handleNewBranch('smsNode')}>
                <PlusOutlined style={{ color: '#0099cc' }} />
                Insert SMS Node Below
              </button>
            ),
          },
        ]
      : []),
    // condition or timer can't select same type as themselfs
    ...(isContentNode
      ? [
          {
            key: 'timerNode',
            label: (
              <button className={styles.addButton} onClick={() => handleNewBranch('timerNode')}>
                <PlusOutlined style={{ color: '#0099cc' }} />
                Insert Timer Node Below
              </button>
            ),
          },
          {
            key: 'conditionNode',
            label: (
              <button className={styles.addButton} onClick={() => handleNewBranch('conditionNode')}>
                <PlusOutlined style={{ color: '#0099cc' }} />
                Insert Condition Node Below
              </button>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className={classNames(styles.nodeWrapper, className)}>
      {!isFirstNode && <Handle type="target" position={Position.Top} className={styles.handle} />}
      {children}
      {isSourceAvailable && (
        <Handle
          type="source"
          position={Position.Bottom}
          className={classNames({
            [styles.handle]: !isBranchable,
            [styles.addBranch]: isBranchable,
            [styles.disabled]: isEditionDisabled,
          })}>
          {isBranchable ? (
            <Dropdown
              disabled={isEditionDisabled}
              menu={{
                items,
              }}
              trigger={['click']}
              placement="bottom">
              <PlusOutlined />
            </Dropdown>
          ) : null}
        </Handle>
      )}
    </div>
  );
};

const NodeHeader = ({ id, Icon, children, deactiveEdit, deactiveCopy, hideActions }) => {
  const { deleteElements, setNodes, getNode, setEdges, getEdges } = useReactFlow();
  const { isEditionDisabled } = useCampaign();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleEdit = (id) => {
    onOpenEditModal(id);
  };

  const handleDeleteNode = (id) => {
    const edges = getEdges();
    const incomingEdges = edges.filter((edge) => edge.target === id);
    const outgoingEdges = edges.filter((edge) => edge.source === id);

    deleteElements({ nodes: [{ id }] });

    if (incomingEdges.length > 0 && outgoingEdges.length > 0) {
      const newEdges = outgoingEdges
        .map((outgoingEdge) => {
          return incomingEdges.map((incomingEdge) => ({
            id: `${incomingEdge.source}-${outgoingEdge.target}`,
            source: incomingEdge.source,
            target: outgoingEdge.target,
          }));
        })
        .flat();

      setEdges((eds) => [
        ...eds.filter((edge) => !incomingEdges.includes(edge) && !outgoingEdges.includes(edge)),
        ...newEdges,
      ]);
    } else if (incomingEdges.length > 0) {
      setEdges((eds) => eds.filter((edge) => !incomingEdges.includes(edge)));
    } else if (outgoingEdges.length > 0) {
      setEdges((eds) => eds.filter((edge) => !outgoingEdges.includes(edge)));
    }

    setIsModalVisible(false);
  };

  const handleDuplicate = (id) => {
    const currentNode = getNode(id);
    if (currentNode) {
      const newNode = {
        ...currentNode,
        id: `${currentNode.type}-${Date.now()}`,
        position: {
          x: currentNode.position.x + 120,
          y: currentNode.position.y + 120,
        },
      };
      setNodes((nodes) => [...nodes, newNode]);
    }
  };

  const showDeleteModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Flex justify="space-between" align="center" className={styles.header}>
        <Flex align="center" gap="small">
          <Icon style={{ color: '#0099cc' }} />
          <Label>{children}</Label>
        </Flex>
        {!hideActions && (
          <Flex align="center" className={styles.actions_container}>
            {/* {!deactiveCopy && <Button onClick={() => handleDuplicate(id)} type="text" icon={<CopyOutlined />} />} */}
            {!deactiveEdit && <Button onClick={() => handleEdit(id)} icon={<EditOutlined />} />}
            <Button onClick={showDeleteModal} icon={<DeleteOutlined />} disabled={isEditionDisabled} />
          </Flex>
        )}
      </Flex>
      <Modal
        title="Confirm Deletion"
        open={isModalVisible}
        onOk={() => handleDeleteNode(id)}
        onCancel={handleCancel}
        okText="Delete"
        cancelText="Cancel">
        <p>Are you sure you want to delete this node?</p>
      </Modal>
    </>
  );
};

const Label = ({ children }) => {
  return (
    <Typography.Text strong style={{ fontSize: '1rem' }}>
      {children}
    </Typography.Text>
  );
};

NodeWrapper.Header = NodeHeader;
NodeWrapper.Label = Label;
