import React from 'react';
import { Modal, Button, Typography, Divider } from 'antd';
import { ExclamationCircleOutlined, StopOutlined } from '@ant-design/icons';
import styles from './archived-confirmation.module.scss';
import { onCloseArchiveModal } from 'redux/campaigns/campaigns-reducer';
import { useSelector } from 'react-redux';

const { Title, Paragraph } = Typography;

export const ArchiveConfirmDialog = ({ handleArchivedType }) => {
  const { isModalArchiveOpen } = useSelector((state) => state.campaigns);

  return (
    <Modal
      open={isModalArchiveOpen}
      onCancel={onCloseArchiveModal}
      footer={[
        <Button key="cancel" type="default" onClick={onCloseArchiveModal}>
          Cancel
        </Button>,
      ]}
      className={styles.modalContent}>
      <div className={styles.header}>
        <ExclamationCircleOutlined className={styles.icon} />
        <Title level={4} className={styles.title}>
          Archive Campaign
        </Title>
      </div>
      <Divider className={styles.divider} />
      <Paragraph className={styles.description}>
        Would you like to stop this campaign immediately or allow leads currently in the flow to complete it?
      </Paragraph>
      <div className="grid grid-cols-1 gap-4 py-2">
        <ArchivedOption
          label="Stop immediately"
          description="Archive the campaign and stop all future communications, including for leads currently in the flow."
          onClick={() => handleArchivedType(false)}
        />
        <ArchivedOption
          label="Allow completion"
          description="Archive the campaign but allow leads already in the flow to receive remaining communications."
          onClick={() => handleArchivedType(true)}
        />
      </div>
    </Modal>
  );
};

const ArchivedOption = ({ label, description, onClick }) => {
  return (
    <button className={styles.option} onClick={onClick}>
      <StopOutlined className={styles.optionIcon} />
      <div>
        <Title level={5} className={styles.optionTitle}>
          {label}
        </Title>
        <Paragraph className={styles.optionDescription}>{description}</Paragraph>
      </div>
    </button>
  );
};
