import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ImageUploadV2 from '../../../../imageUpload/v2/ImageUploadV2';
import Validation from 'components/form/Validation';
import { _uploadSchoolEmailCampaingImageV2 } from 'controllers/schools/schools_controller';
import { getS3Bucket } from 'redux/school/school-actions';
import { is } from 'immutable';

const ALLOWED_EXTENSIONS = /(\.jpg|\.jpeg|\.png)$/i;
const IMG_DEFAULT =
  'https://scholamatch-static.s3-us-west-2.amazonaws.com/v2/dashboard-crm/v3/campaigns/icon-custom-img.svg';
const MAX_SIZE_ALLOWED_BYTES = 5000000; // 5MB

const ImageUploader = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [setWidth, setSetWidth] = useState(window.innerWidth);
  const [s3Bucket, setS3Bucket] = useState('');
  const [url, setUrl] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const loadUrlS3Bucket = async () => {
      const response = await dispatch(getS3Bucket());
      if (response.json && response.json.length > 0) {
        setS3Bucket(response.json[0]);
      }
    };
    loadUrlS3Bucket();

    const handleWindowResize = () => setSetWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [dispatch]);

  const onSave = (file) => {
    const { school_id: schoolId, campaign, image_key: imageKey, onSuccess } = props;
    const { id } = campaign;
    return _uploadSchoolEmailCampaingImageV2(schoolId, id, file).then((res) => {
      const payload = { key: imageKey, value: res.url };
      onSuccess(payload);
      return res.url;
    });
  };

  const validateFile = async () => {
    const fileToUpload = document.getElementById(props.id);
    let newError = {};
    if (fileToUpload && fileToUpload.files.length > 0) {
      const file = fileToUpload.files[0];
      if (!ALLOWED_EXTENSIONS.exec(file.name))
        newError.image = [
          'We could not recognize the file type. Make sure it has an extension ending in .jpg, .jpeg or .png',
        ];
      if (file.size >= MAX_SIZE_ALLOWED_BYTES) newError.image = ['File size too big. Please upload a smaller file.'];
      // TODO: Dimensions validation missing
      if (!newError.image || newError.image.length === 0) {
        onSave(file).then((resolvedUrl) => setUrl(resolvedUrl));
      }
    }
    setError(newError);
  };

  const newValidateFile = async (file) => {
    let newError = {};
    if (!newError.image || newError.image.length === 0) {
      onSave(file).then((resolvedUrl) => setUrl(resolvedUrl));
    }
    setError(newError);
  };

  const getUrlImage = (image) => {
    if (!image) return '';
    const { school_id, campaign, params } = props;
    const [mainUrl, imageName] = image.split(`school/${school_id}/email-campaign/${campaign.id}/`);
    const DEFAULT_URL = image;
    let formatName = '';
    if (imageName && (imageName.indexOf('l-') > -1 || imageName.indexOf('m-') > -1 || imageName.indexOf('s-') > -1)) {
      formatName = imageName.substr(2);
    } else if (imageName && imageName.indexOf('original-') > -1) {
      formatName = imageName.substr(9);
    } else {
      formatName = imageName;
    }
    let urlRes = '';
    if (setWidth > 768 && setWidth < 1200) {
      urlRes = `${mainUrl}school/${params.id}/email-campaign/${campaign.id}/l-${formatName}`;
    } else if (setWidth <= 768 && setWidth > 480) {
      urlRes = `${mainUrl}school/${params.id}/email-campaign/${campaign.id}/m-${formatName}`;
    } else if (setWidth <= 480) {
      urlRes = `${mainUrl}school/${params.id}/email-campaign/${campaign.id}/s-${formatName}`;
    } else {
      urlRes = DEFAULT_URL;
    }
    return urlRes;
  };

  const { URL, size, campaign, disabled, remove_text: removeText, title } = props;
  let _URL = URL;
  const isSMSsimple = campaign?.platform === 'sms' && campaign?.type !== 'Advanced';
  /* console.log(isSMSsimple); */
  if (isSMSsimple) {
    if (URL && URL.includes('imagen-custom-campaign.png')) _URL = '';
  }
  const [images, setImages] = useState([]);
  const urlImg = url || _URL || IMG_DEFAULT;
  useEffect(() => {
    if (urlImg !== IMG_DEFAULT) {
      setImages([
        {
          filename: urlImg,
          school_id: props.school_id,
          campaign_id: props.campaign.id,
        },
      ]);
    } else {
      setImages([]);
    }
  }, [urlImg, props.campaign.platform, props.school_id, props.campaign.id]);
  return (
    <div className="school-logo-content">
      {/* ...existing code... */}
      <div className="school-logo-actions">
        <div className="school-logo-buttons">
          <Validation messages={error?.image}>
            <>
              <ImageUploadV2
                id={props.id}
                multiple={false}
                maxFiles={1}
                typeImage=".png, .jpg, .jpeg"
                styleCss={{ minWidth: 320, height: 110 }}
                aspectRatioValue={1 / 1}
                selectAspectRatio={size === 'logo' ? ['1:1'] : ['16:9', '3:2', '1:1', '4:3', '18:6']}
                onSave={newValidateFile}
                images={images}
                getUrlImage={getUrlImage}
                isDeleted={false}
                isLogoSchool
                showPreview
                s3Bucket={s3Bucket}
                singleImage
                hideEdit={images.length === 1 && images[0].filename.endsWith('/campaigns/school-logo.png')}
                isSMSplatform={props.campaign.platform === 'sms'}
              />
              {urlImg !== IMG_DEFAULT && (
                <span
                  className={
                    title === 'Image'
                      ? 'remove-text-image d-flex align-items-center font-14'
                      : 'remove-text-logo d-flex align-items-center font-14'
                  }>
                  <span
                    onClick={(e) => !disabled && (setUrl(null), props.onRemoveImage(e))}
                    style={{ cursor: 'pointer' }}>
                    <img
                      height="16"
                      className="mr-2"
                      src="https://scholamatch-static.s3-us-west-2.amazonaws.com/close.svg"
                      alt="close"
                    />
                    {removeText || 'Add Logo'}
                  </span>
                </span>
              )}
            </>
          </Validation>
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
