import React from 'react';
import { Card } from 'components/card/card';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './stat-card.module.scss';

export const StatCard = ({ title, value, total, percentage, icon: Icon, trend, comparison, color = 'primary' }) => {
  const colorClasses = {
    primary: styles.textPrimary,
    success: styles.textSuccess,
    warning: styles.textWarning,
    destructive: styles.textDestructive,
  };

  const bgColorClasses = {
    primary: styles.bgPrimary,
    success: styles.bgSuccess,
    warning: styles.bgWarning,
    destructive: styles.bgDestructive,
  };

  const iconBgColorClasses = {
    primary: styles.iconBgPrimary,
    success: styles.iconBgSuccess,
    warning: styles.iconBgWarning,
    destructive: styles.iconBgDestructive,
  };

  const ringColorClasses = {
    primary: styles.ringPrimary,
    success: styles.ringSuccess,
    warning: styles.ringWarning,
    destructive: styles.ringDestructive,
  };

  return (
    <Card className={styles.card}>
      <div className={styles.cardHeader}>
        <div>
          <p className={styles.cardTitle}>{title}</p>
          <h3 className={styles.cardValue}>{value}</h3>
          <p className={styles.cardTotal}>{total ? `out of ${total}` : ''}</p>
        </div>
        <div className={classNames(styles.iconContainer, iconBgColorClasses[color], ringColorClasses[color])}>
          <Icon className={classNames(styles.icon, colorClasses[color])} />
        </div>
      </div>
      {comparison && (
        <>
          <hr className={styles.divider} />
          <div className={`${styles.trendContainer} spacing-mb-16`}>
            <span className={styles.range}>{comparison?.split('vs')[0]}</span>
          </div>
          <div className={`${styles.grayContainer}`}>
            <div className={styles.percentageContainer}>
              <svg className={styles.percentageSvg}>
                <circle cx="56" cy="56" r="36" className={classNames(styles.percentageCircle, styles.lightGray)} />
                <circle
                  cx="56"
                  cy="56"
                  r="36"
                  strokeDasharray={`${2 * Math.PI * 36}`}
                  strokeDashoffset={`${2 * Math.PI * 36 * (1 - percentage / 100)}`}
                  className={classNames(styles.percentageCircle, colorClasses[color])}
                />
              </svg>

              <div className={styles.percentageText}>
                <span className={colorClasses[color]}>{percentage}%</span>
              </div>
            </div>
          </div>

          <div className={styles.trendContainer}>
            {trend !== '' && !trend.startsWith('-') && (
              <ArrowUpOutlined className={classNames(styles.trendIcon, styles.textSuccess)} />
            )}
            {trend !== '' && trend.startsWith('-') && (
              <ArrowDownOutlined className={classNames(styles.trendIcon, styles.textDestructive)} />
            )}
            <span className={styles.textSuccess}>{trend}</span>
            <span className={styles.range}>vs {comparison?.split('vs')[1]}</span>
          </div>
        </>
      )}
      <div className={classNames(styles.bottomBar, bgColorClasses[color])} />
    </Card>
  );
};
