import { useEffect, useState } from 'react';
import { useCampaign, useFlowDesigner } from '../flow-designer/hooks';
import { useUpdateBrandingSchool } from '../../hooks/useUpdateBrandingSchool';
import { Button } from '../../components';
import { Typography, Flex, Badge, Tabs } from 'antd';
import { LeftOutlined, RightOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { browserHistory } from 'react-router';
import { SectionTitle } from 'components/pages/campaigns/components/shared/input-campaign';
import styles from './campaign-builder.module.scss';
import { AdvancedCampaignsAudience } from '../advanced-campaigns/components/advanced-campaigns-audience';
import { Flow } from '..';
import { AdvancedCampaignsSummary } from '../advanced-campaigns/components/advanced-campaigns-summary';
import { ReactFlowProvider } from '@xyflow/react';
import { SelectorNode } from '../flow-designer/components';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import CampaingConstants from 'components/pages/campaigns/utils/CampaingConstants';

export const CampaignBuilder = ({ params }) => {
  return (
    <ReactFlowProvider>
      <FlowCampaign params={params} />
    </ReactFlowProvider>
  );
};

const FlowCampaign = ({ params }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { campaign_id: campaignId, id: schoolId } = params;

  const { currentSchool } = useSelector((state) => state.school);

  const { leadCount } = useSelector((state) => state.campaigns);

  // call campaign
  const { initCampaign, campaign, getParamValue } = useCampaign();
  useEffect(() => {
    initCampaign(schoolId, campaignId);
  }, [schoolId, campaignId]);

  const flowDesigner = useFlowDesigner(campaign || {});
  const { isEmptyNode, onNodeClick, selectedNode, summary, nodes } = flowDesigner;

  useUpdateBrandingSchool({ campaign, currentSchool, nodes });

  const trigger = getParamValue('{{trigger}}');
  const runOnPublish = getParamValue('run_on_publish');
  const isTriggered = trigger === CampaingConstants.TRIGGER_ADD_SCHOLA_SYSTEM_VALUE;

  // handle tab section
  const [tab, setTab] = useState('audience');

  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  const handleOpenTab = () => setIsCollapsed(false);

  if (!campaign.params) return null;

  return (
    <main className={classNames(styles.container, 'advanced-campaigns-builder')}>
      <Flex align="center" justify="space-between">
        <Typography.Title style={{ fontSize: '1.5rem' }}>{campaign?.name}</Typography.Title>
      </Flex>
      <section className={isCollapsed ? styles.layout_collapsed : styles.layout}>
        <aside className={isCollapsed ? styles.aside_collapsed : styles.aside}>
          <div className="aside-container">
            <Flex align="center" justify="space-between" style={isCollapsed ? { display: 'none', width: '35px' } : {}}>
              <div>
                <SectionTitle>Campaign Builder</SectionTitle>
                <Typography.Paragraph className={styles.step}>
                  Build your campaign audience and flow
                </Typography.Paragraph>
              </div>
              <CampaignStatus status={campaign?.status} />
            </Flex>

            <Tabs
              tabPosition={isCollapsed ? 'left' : 'top'}
              defaultActiveKey={tab}
              className={isCollapsed ? 'advanced-campaign-tabs' : ''}
              onTabClick={handleOpenTab}
              centered
              tabBarGutter={54}
              items={[
                {
                  key: 'audience',
                  label: 'Audience',
                  icon: <UserOutlined />,
                  children: isCollapsed ? (
                    ''
                  ) : (
                    <AdvancedCampaignsAudience
                      schoolId={schoolId}
                      isTriggered={isTriggered}
                      runOnPublish={runOnPublish}
                    />
                  ),
                },
                {
                  key: 'flow',
                  label: 'Flow',
                  icon: <SettingOutlined />,
                  children: isCollapsed ? (
                    ''
                  ) : (
                    <SelectorNode
                      campaignType={campaign?.platform}
                      onNodeClick={onNodeClick}
                      isEmptyNode={isEmptyNode}
                      selectedNode={selectedNode}
                    />
                  ),
                },
                {
                  key: 'summary',
                  label: 'Summary',
                  icon: <SettingOutlined />,
                  children: isCollapsed ? (
                    ''
                  ) : (
                    <AdvancedCampaignsSummary
                      leadsCount={leadCount}
                      summary={summary}
                      isTriggered={isTriggered}
                      includeCurrentLeads={JSON.parse(runOnPublish || 'false')}
                    />
                  ),
                },
              ]}
              onChange={setTab}
            />
          </div>
          <Button
            className={styles.button_collapse}
            type="default"
            variant="outlined"
            shape="circle"
            onClick={handleCollapse}
            icon={isCollapsed ? <RightOutlined /> : <LeftOutlined />}
          />
        </aside>
        <Flow params={params} flowDesigner={flowDesigner} currentSchool={currentSchool} />
      </section>
    </main>
  );
};

const CampaignFooter = ({ schoolId, primaryLabel, onClick }) => {
  return (
    <Flex align="center" justify="space-between" style={{ marginTop: '1rem' }}>
      <Button
        size="large"
        type="default"
        onClick={() => browserHistory.push(`/admin/v2/schools/${schoolId}/communication-hub/campaigns-center`)}>
        Back
      </Button>
      <Flex align="center" gap="middle" style={{ marginTop: '1rem' }}>
        <Button onClick={onClick} size="large">
          {primaryLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

const CampaignStatus = ({ status }) => {
  const statusTypes = {
    Active: 'success',
    Draft: 'processing',
    Paused: 'warning',
    Archived: 'default',
  };

  const statusType = statusTypes[status] || 'Draft'; // Valor predeterminado

  return (
    <div className={`badge-container badge-container-status-${statusType}`}>
      <Badge status={statusType} text={status || 'Draft'} />
    </div>
  );
};

CampaignBuilder.Status = CampaignStatus;
CampaignBuilder.Footer = CampaignFooter;
