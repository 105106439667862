import React from 'react';
import { Radio, Typography } from 'antd';
import styles from './audience-choose.module.scss';
import { useSelector } from 'react-redux';

export const AudienceChoose = ({ runOnPublish, onIncludeAudience }) => {
  const { leadCount } = useSelector((state) => state.campaigns);

  return (
    <div className={styles.audienceChooseContainer}>
      <Typography.Title level={5}>Audience Targeting</Typography.Title>
      <Radio.Group
        name="runOnPublish"
        onChange={(e) => onIncludeAudience(e.target.value)}
        defaultValue={runOnPublish !== undefined ? runOnPublish : 'false'}>
        <RadioOption
          value="true"
          label="Send to current audience and future leads"
          description={`Campaign will be sent to ${leadCount} existing leads and include new leads who match these criteria`}
        />
        <RadioOption
          value="false"
          label="Only send to future leads"
          description="Campaign will be sent to only new leads who match these criteria going forward"
        />
      </Radio.Group>
    </div>
  );
};

const RadioOption = ({ value, label, description }) => (
  <div className={styles.radioGroup}>
    <Radio value={value} className={styles.radioOption}>
      <Typography.Title level={5} className={styles.boldText}>
        {label}
      </Typography.Title>
    </Radio>
    <div className={styles.radioDescription}>
      <Typography.Text>{description}</Typography.Text>
    </div>
  </div>
);
