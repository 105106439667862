import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNodesState, useEdgesState, addEdge, useReactFlow, useOnSelectionChange } from '@xyflow/react';
import { useNodePlacement, useUpdateNode } from '.';
import { getDateTimeFromUtcToLocal } from '../utils/schedule-utils';

export const useFlowDesigner = (campaign) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [rfInstance, setRfInstance] = useState(null);
  const [isInitialized, setIsInitialized] = useState(true);

  const { handleNewNode } = useUpdateNode();

  const { fitView, setViewport } = useReactFlow();

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();

      return Object.entries(flow).map(([key, value]) => ({
        key,
        value,
      }));
    }
  }, [rfInstance]);

  const getFlowElements = (key) => {
    const item = campaign.params?.find((item) => item.key === key);
    if (item?.value) {
      return item.value;
    }
  };

  // simulate async node map
  useEffect(() => {
    if (campaign) {
      const nodes = getFlowElements('nodes');
      const edges = getFlowElements('edges');
      const viewport = getFlowElements('viewport') || {};

      restoreFlow({ nodes, edges, viewport }).then(() => setIsInitialized(false));
    }
  }, [campaign]);

  const restoreFlow = async ({ nodes, edges, viewport }) => {
    const triggerValue = getFlowElements('{{trigger}}');
    const schedule = getFlowElements('{{schedule}}');
    const tails = getFlowElements('tails') || [];

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD

    const parsedSchedule = schedule
      ? JSON.parse(schedule)
      : { start_date: formattedDate, start_time: '12:00 AM', selectTimeZone: '(GMT-05:00) Eastern Time' };

    const { date, time, timeZone } = getDateTimeFromUtcToLocal(
      parsedSchedule.start_date,
      parsedSchedule.start_time,
      parsedSchedule.selectTimeZone
    );

    const triggerNode = {
      id: 'start',
      type: 'triggerNode',
      position: { x: 250, y: 100 },
      deletable: false,
      data: {
        trigger: triggerValue,
        start_date: date,
        start_time: time,
        selectTimeZone: timeZone,
      },
    };

    const displayNodes = [triggerNode, ...nodes];

    // Find all nodes that do not have any outgoing edges
    const lastNodes = displayNodes.filter((node) => !edges.some((edge) => edge.source === node.id));

    const placeholderNodes = lastNodes.map((lastNode, index) => {
      const tail = tails.find((t) => t.placeholderId === `placeholder-${index}`);
      const lastPosition = lastNode.position;
      return {
        id: tail ? tail.placeholderId : `placeholder-${index}`,
        type: tail ? tail.status : 'placeholderNode',
        position: { ...lastPosition, y: lastPosition.y + 350 },
        data: { nodeSelection: '' },
        deletable: false,
      };
    });

    const placeholderEdges = lastNodes.map((lastNode, index) => ({
      id: `${lastNode.id}->placeholder-${index}`,
      animated: true,
      source: lastNode.id,
      target: `placeholder-${index}`,
    }));

    // Provide default values
    const { x = 0, y = 0, zoom = 1 } = viewport;

    return new Promise((resolve) => {
      if (isInitialized) {
        setNodes([...displayNodes, ...placeholderNodes] || []);
        setEdges([...edges, ...placeholderEdges] || []);
      }
      resolve();
    });
  };

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const { calculateNodePosition } = useNodePlacement();

  const placeholders = nodes.filter((node) => node.id.includes('placeholder'));

  const [selectedNode, setSelectedNode] = useState(null);
  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      setSelectedNode(nodes[0]);
    },
  });

  const createNode = (type) => {
    // following business logic, the selected node must be a placeholder
    let id;

    if (selectedNode?.type !== 'placeholderNode') {
      id = placeholders[0].id;
    } else {
      id = selectedNode.id;
    }

    handleNewNode(type, id, true);
  };

  const onNodeClick = useCallback(
    (type) => {
      createNode(type, null);
    },
    [createNode]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData('application/reactflow');
      if (!type) return;
      createNode(type, event);
    },
    [createNode]
  );

  const isEmptyNode = useMemo(() => {
    const isEmpty = nodes.length === 2;
    return isEmpty;
  }, [nodes]);

  const getNodeSequence = useCallback(() => {
    const startNode = nodes.find((node) => node.type === 'triggerNode');
    if (!startNode) return [];

    const sequence = [];
    const visitedNodes = new Set();

    const traverseNodes = (nodeId) => {
      const currentNode = nodes.find((node) => node.id === nodeId);
      if (!currentNode || visitedNodes.has(nodeId)) return;

      visitedNodes.add(nodeId);

      const outgoingEdges = edges.filter((edge) => edge.source === nodeId);
      sequence.push({
        id: currentNode.id,
        type: currentNode.type,
        data: {
          ...(currentNode.type === 'emailNode'
            ? { subject: currentNode.data.find((item) => item.key === '{{subject}}').value }
            : { ...currentNode.data }),
        },
      });

      outgoingEdges.forEach((edge) => traverseNodes(edge.target));
    };

    traverseNodes(startNode.id);

    return sequence;
  }, [nodes, edges]);

  const statements = {
    emailNode: 'send email',
    smsNode: 'send SMS',
    conditionNode: 'If recipient',
    timerNode: 'wait',
  };
  const conditions = {
    true: 'does',
    false: 'does not',
  };
  const actionsStatements = {
    'open-last-message': 'Open Last Message',
    'reply-last-message': 'Reply to Last Message',
    'clicks-application-link': 'Click Application Link',
    'completes-application': 'Completed Application',
    'clicks-custom-link': 'Clicks Custom Link',
    unsubscribes: 'Unsubscribes',
  };

  const summary = useMemo(() => {
    const nodeSequence = getNodeSequence();

    const triggerValue = getFlowElements('{{trigger}}');
    let summary = 'When ';
    summary += triggerValue === 'none' ? 'scheduled time comes' : 'the campaign begins';
    summary += ' it will immediately ';

    nodeSequence.forEach((seq) => {
      if (seq.id === 'start') return;

      if (seq.type === 'emailNode') {
        summary += `${statements[seq.type]} ${seq.data.subject}. `;
      }

      if (seq.type === 'smsNode') {
        summary += `${statements[seq.type]}. `;
      }

      if (seq.type === 'conditionNode') {
        summary += `${statements[seq.type]} ${conditions[seq.data.condition]} ${
          actionsStatements[seq.data.userAction]
        } then `;
      }

      if (seq.type === 'timerNode') {
        summary += `${statements[seq.type]} ${seq.data.time} ${seq.data.timeframe}, `;
      }

      if (seq.type === 'placeholderNode' || seq.type === 'endNode') {
        summary += 'is ended. ';
      }
    });

    return summary;
  }, [nodes]);

  return {
    onNodesChange,
    onEdgesChange,
    setRfInstance,
    nodes,
    edges,
    onConnect,
    onDragOver,
    onNodeClick,
    onDrop,
    onSave,
    isEmptyNode,
    selectedNode,
    summary,
  };
};
