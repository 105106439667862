import { ReactFlowProvider, ReactFlow, Controls, Background } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import styles from './flow-designer.module.scss';
import {
  EditNodeContent,
  NodeCondition,
  NodeEmail,
  NodeEnd,
  NodePlaceholder,
  NodeSms,
  NodeTimer,
  NodeTrigger,
  SaveUpdatedCampaign,
} from './components';
import { useCampaign } from './hooks';
import { _addMarketingCampaign, _getMarketingCampaign } from 'controllers/schools/schools_controller';
import { useSelector } from 'react-redux';
import { onOpenSaveModal } from 'redux/campaigns/campaigns-reducer';
import { CampaignBuilder } from '..';
import { getParam } from './utils/getParam';

export const FlowDesigner = ({ params }) => {
  return (
    <ReactFlowProvider>
      <Flow params={params} />
    </ReactFlowProvider>
  );
};

export const Flow = ({ params, flowDesigner, currentSchool }) => {
  const { id: schoolId, campaign_id: campaignId } = params;

  const { campaign, setCampaignParam, isEditionDisabled } = useCampaign();

  const { nodes, edges, onNodesChange, onEdgesChange, setRfInstance, onConnect, onDragOver, onDrop, onSave } =
    flowDesigner;

  const { nodeId } = useSelector((state) => state.campaigns);

  const nodeTypes = {
    emailNode: NodeEmail,
    smsNode: NodeSms,
    timerNode: NodeTimer,
    conditionNode: NodeCondition,
    triggerNode: NodeTrigger,
    placeholderNode: NodePlaceholder,
    endNode: NodeEnd,
  };

  const edgeOptions = {
    type: 'smoothstep',
  };

  const updateFlow = () => {
    const flow = onSave();

    const nodes = getParam(flow, 'nodes');
    const edges = getParam(flow, 'edges');
    const viewport = getParam(flow, 'viewport');

    const nodesToSave = nodes.value.filter((node) => node.id !== 'start' && !node.id.includes('placeholder'));
    const edgesToSave = edges.value.filter((edge) => !edge.target.includes('placeholder'));

    setCampaignParam({ key: 'nodes', value: nodesToSave });
    setCampaignParam({ key: 'edges', value: edgesToSave });

    onOpenSaveModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.canva}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={isEditionDisabled ? null : onEdgesChange}
          // onConnect={isEditionDisabled ? null : onConnect}
          onDragOver={isEditionDisabled ? null : onDragOver}
          onDrop={isEditionDisabled ? null : onDrop}
          onInit={setRfInstance}
          fitView
          fitViewOptions={{
            padding: 0.2,
            minZoom: 0.5,
            maxZoom: 1,
            duration: 500,
          }}
          minZoom={0.2}
          maxZoom={2}
          defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
          nodeTypes={nodeTypes}
          defaultEdgeOptions={edgeOptions}
          deleteKeyCode={null}>
          <Controls />
          <Background />
        </ReactFlow>
      </div>
      {/* Control buttons */}
      <CampaignBuilder.Footer schoolId={schoolId} primaryLabel="Confirm" onClick={updateFlow} />

      {/* Edit Content from node selected */}
      {currentSchool && <EditNodeContent nodeId={nodeId} campaign={campaign} currentSchool={currentSchool} />}

      <SaveUpdatedCampaign schoolId={schoolId} campaignId={campaignId} />
    </div>
  );
};
