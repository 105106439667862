import includes from 'lodash/includes';

const moment = require('moment');

const _planName = (school) => {
  let name = 'scholabasics';
  if (school.has_premium_access) {
    if (school.plan_id.toLowerCase() === 'monthlypro' && school.subscription_months > 0) {
      // Recruiter Pro
      name = 'scholarecruiterpro';
    } else {
      // Plus
      switch (school.plan_id.toLowerCase()) {
        case 'monthlyconnect':
        case 'monthlyconnect2':
        case 'yearlyconnect':
        case 'yearlyconnect2':
        case 'monthlyrecruiter':
        case 'monthlyrecruiter2':
        case 'yearlyrecruiter':
        case 'yearlyrecruiter2':
        case 'scholaplus':
          name = 'scholaplus';
          break;
        case 'monthlypro':
        case 'yearlypro':
        case 'payg':
        case 'scholaone':
        case 'scholarecruiterpro':
        case 'scholarecruiterpro-evergreen':
          name = 'scholarecruiterpro';
          break;
        case 'scholabasics':
          name = 'scholabasics';
          break;
      }
    }
  }
  return name;
};

const allPermissions = ['reachout', 'publish-campaign', 'view-lead-phone-email', 'export-leads', 'export-apps'];

const plans = [
  {
    planName: 'scholabasics',
    expiredays: 1,
    permissions: allPermissions.filter((item) => {
      return !['reachout', 'publish-campaign', 'view-lead-phone-email', 'export-leads', 'export-apps'].includes(item);
    }),
  },
  {
    planName: 'scholaplus',
    expiredays: 7,
    permissions: allPermissions.filter((item) => {
      return !['export-leads', 'export-apps'].includes(item);
    }),
  },
  {
    planName: 'scholarecruiterpro',
    expiredays: 0,
    permissions: allPermissions,
  },
];

export const formatGrade = (grade) => {
  let ordinal = '';
  const excludes = ['pre-k', 'tk', 'k'];
  if (!includes(excludes, grade.trim().toLowerCase())) {
    switch (grade.trim().toLowerCase()) {
      case 'hs':
        grade = 'Head Start';
        break;
      case '1':
        ordinal = 'st';
        break;
      case '2':
        ordinal = 'nd';
        break;
      case '3':
        ordinal = 'rd';
        break;
      case 'n/a':
        ordinal = '';
        break;
      default:
        ordinal = 'th';
        break;
    }
  }
  return grade && grade !== '' ? `${grade}${ordinal}` : 'N/A';
};

export const formatGrades = (grades) => {
  if (!grades || grades.trim === '') {
    return 'N/A';
  }

  if (grades.trim().toLowerCase() === 'all') {
    return grades;
  }

  let result = '';
  let arrayGrades = grades.split(',');
  for (let index = 0; index < arrayGrades.length; index++) {
    let grade = arrayGrades[index];
    if (index != 0) {
      result += ', ';
    }
    result += formatGrade(grade);
  }
  return result;
};

export const currencyFormat = (num) => {
  if (num == undefined || num === null) {
    return null;
  }
  return `$${Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const planName = (school) => {
  return _planName(school);
};

export const hasPremium = (school) => {
  return school?.has_premium_access;
};

export const hasPermission = (school, option) => {
  const planName = _planName(school);
  let plan = plans.filter((p) => {
    return p.planName === planName;
  });

  return plan[0] && plan[0].permissions.includes(option);
};

export const expiredays = (school) => {
  const planName = _planName(school);
  let plan = plans.filter((p) => {
    return p.planName === planName;
  });

  return plan[0] && plan[0].expiredays;
};

export const holidaysDates = (schoolId) => {
  // replace with function to get days from db based on school id
  const holidaysDates = [
    '2024-01-01',
    '2024-01-15',
    '2024-02-19',
    '2024-05-27',
    '2024-06-19',
    '2024-07-04',
    '2024-09-02',
    '2024-10-14',
    '2024-11-11',
    '2024-11-28',
    '2024-12-25',
  ];

  return holidaysDates;
};

export const formatDate = (date) => {
  if (date === 'N/A') return date;

  const dt = moment(date);
  if (dt.isValid()) return moment(date.split('T')[0]).format('YYYY-MM-DD');
  return 'N/A';
};

export const formatUTCDate = (date) => {
  if (date === 'N/A') return date;

  const dt = moment(date);
  if (dt.isValid()) return moment.utc(date).local().format('YYYY-MM-DD');
  return 'N/A';
};

export const formatDateTime = (date, format = 'YYYY-MM-DD') => {
  const dt = moment(date);
  if (dt.isValid()) {
    return dt.format(format);
  }
  return 'N/A';
};

export const formatPhoneNumber = (v) => {
  let r = v.replace(/\D/g, '');

  if (r.length === 0) {
    return v;
  }

  if (r.length > 10) {
    return v;
  }
  if (r.length === 10) {
    r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, '($1) $2-$3');
    return r;
  }
  if (r.length > 6) {
    r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, '($1) $2-$3');
  } else if (r.length > 3) {
    r = r.replace(/^(\d\d\d)(\d{0,4})/, '($1) $2');
  } else {
    r = r.replace(/^(\d*)/, '($1)');
  }

  return r;
};
