import { useEffect, useRef, useState } from 'react';
import { useToggle } from 'hooks/useToggle';
import { Flex, Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styles from './campaign-name.module.scss';
import { useCampaign } from '../../views/flow-designer/hooks';

export const CampaignName = ({ name, onCampaignNameChange }) => {
  const [localName, setLocalName] = useState(name);
  const [isEditing, toggleEditing] = useToggle(false);
  const { updateCampaignValue } = useCampaign();
  const inputRef = useRef(null);

  useEffect(() => {
    setLocalName(name);
  }, [name]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleChange = (e) => {
    const { selectionStart, selectionEnd } = e.target;
    const newValue = e.target.value;
    setLocalName(newValue);
    updateCampaignValue({ key: 'name', value: newValue });
    if (onCampaignNameChange) {
      onCampaignNameChange(newValue);
    }
    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(selectionStart, selectionEnd);
      }
    });
  };

  return (
    <Flex gap="middle" align="center">
      <Input
        ref={inputRef}
        className={styles.inputTitle}
        value={localName}
        variant="borderless"
        onChange={handleChange}
        style={{ fontSize: '22px' }}
      />
      <Button size="large" type="default" onClick={toggleEditing} icon={<EditOutlined />} />
    </Flex>
  );
};
