import { useMemo } from 'react';
import { Modal, Typography } from 'antd';
import { Button, SettingSelector, TemplateSelector } from '..';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { STAGES, useCampaignSettings } from '../../hooks/useCampaignSettings';
import { useTemplates } from '../../hooks/useCampaignTemplates';
import { useSelector } from 'react-redux';
import CampaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import { LAYOUTS } from 'components/pages/campaigns/components/email/Layouts';
import styles from './campaign-creation-settings.module.scss';
import { getGlobalParams } from '../../views/flow-designer/hooks';

const { Title } = Typography;

export const CampaignCreationSettings = ({ schoolId, isVisible = true, toggleVisible }) => {
  const { currentSchool } = useSelector((state) => state.school);

  const templates = useTemplates();
  const defaultParams = useMemo(() => getGlobalParamsBySchool(currentSchool, templates[0]), [currentSchool, templates]);

  const {
    stage,
    settings,
    optionsModes,
    optionsAdvanced,
    optionsSimple,
    optionsRecurrence,
    onTemplateSelect,
    backToMode,
    titles,
    advancedTemplates,
    status,
  } = useCampaignSettings({ schoolId, templates, defaultParams });

  const options = {
    mode: optionsModes,
    simple: optionsSimple,
    recurrence: optionsRecurrence,
    advanced: optionsAdvanced,
  };

  return (
    <Modal open={isVisible} onCancel={toggleVisible} footer={null}>
      <div className={styles.header}>
        <Button
          type="ghost"
          shape="circle"
          icon={<ArrowLeftOutlined />}
          onClick={backToMode}
          className={styles.backButton}
        />
        <Title style={{ marginBottom: 0, textTransform: 'capitalize' }} level={4}>
          {titles[stage]}
        </Title>
      </div>

      {stage !== STAGES.TEMPLATE ? (
        <SettingSelector
          options={stage === STAGES.MODE || stage === STAGES.RECURRENCE ? options[stage] : options[settings.mode]}
        />
      ) : (
        <TemplateSelector
          status={status}
          templates={settings.mode === 'simple' ? templates : advancedTemplates}
          onTemplateSelect={onTemplateSelect}
        />
      )}
    </Modal>
  );
};

export const getGlobalParamsBySchool = (school, template) => {
  if (!school) return [];

  const { NAME_KEY } = CampaingConstants;
  const [layout] = LAYOUTS;
  const {
    SCHOOL_LOGO_KEY,
    MEDIA_FACEBOOK_KEY,
    MEDIA_INSTAGRAM_KEY,
    MEDIA_TWITTER_KEY,
    MEDIA_YOUTUBE_KEY,
    PRIMARY_COLOR_KEY,
    SECONDARY_COLOR_KEY,
  } = layout.keys;

  const {
    name,
    logo_image,
    social_facebook,
    social_instagram,
    social_twitter,
    social_youtube,
    primary_color,
    secondary_color,
  } = school;

  const schoolParams = [
    { key: NAME_KEY, value: name || '' },
    {
      key: SCHOOL_LOGO_KEY,
      value: logo_image,
    },
    { key: MEDIA_FACEBOOK_KEY, value: social_facebook || '' },
    { key: MEDIA_INSTAGRAM_KEY, value: social_instagram || '' },
    { key: MEDIA_TWITTER_KEY, value: social_twitter || '' },
    { key: MEDIA_YOUTUBE_KEY, value: social_youtube || '' },
    { key: PRIMARY_COLOR_KEY, value: primary_color || '#197f9c' },
    { key: SECONDARY_COLOR_KEY, value: secondary_color || '#ffffff' },
  ];

  const schoolParamsMap = new Map(schoolParams.map((param) => [param.key, param.value]));

  const updatedParams = getGlobalParams(template?.params)?.map((param) => {
    if (schoolParamsMap.has(param.key)) {
      return { ...param, value: schoolParamsMap.get(param.key) || param.value };
    }
    return param;
  });

  schoolParams.forEach((param) => {
    if (!updatedParams?.some((p) => p.key === param.key)) {
      updatedParams?.push(param);
    }
  });

  return updatedParams;
};
