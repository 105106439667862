import { ContentPreview, NodeWrapper } from '.';
import { MessageOutlined } from '@ant-design/icons';

export const NodeSms = ({ data, id, type, positionAbsoluteX, positionAbsoluteY }) => {
  const previewText = data.find((item) => item.key === '{{message_text}}').value || 'No content yet';

  const truncatedPreviewText = previewText.length > 50 ? `${previewText.substring(0, 50)}...` : previewText;

  return (
    <NodeWrapper nodeType={type} isBranchable id={id} position={{ x: positionAbsoluteX, y: positionAbsoluteY }}>
      <NodeWrapper.Header id={id} Icon={MessageOutlined}>
        Send SMS
      </NodeWrapper.Header>
      <ContentPreview>{truncatedPreviewText}</ContentPreview>
    </NodeWrapper>
  );
};
