import moment from 'moment';
import campaingConstants from 'components/pages/campaigns/utils/CampaingConstants';

export const timeZones = [
  { value: '(GMT-05:00) Eastern Time', label: 'Eastern' },
  { value: '(GMT-06:00) Central Time', label: 'Central' },
  { value: '(GMT-07:00) Mountain Time', label: 'Mountain' },
  { value: '(GMT-08:00) Pacific Time', label: 'Pacific' },
];

export const getTimeFromLocalToUtc = (date, time, timeZone) => {
  timeZone = getTimeZone(timeZone);
  let isValidDate = true;
  let offsetMinutes = getOffsetMinutes(timeZone);
  if (!time) {
    time = campaingConstants.DEFAULT_START_TIME;
  }
  if (moment.utc(time, campaingConstants.FORMAT_TIME, true).isValid() === false) {
    time = campaingConstants.DEFAULT_START_TIME;
  }
  if (moment.utc(date, campaingConstants.FORMAT_DATE, true).isValid() === false) {
    if (moment.utc(date).isValid() === true) {
      date = moment.utc(date).format(campaingConstants.FORMAT_DATE);
    } else {
      isValidDate = false;
      date = moment.utc().format(campaingConstants.FORMAT_DATE);
    }
  }

  let dateTime = moment.utc(`${date} ${time}`, `${campaingConstants.FORMAT_DATE} ${campaingConstants.FORMAT_TIME}`);
  if (offsetMinutes !== 0) {
    offsetMinutes *= -1;
    dateTime = dateTime.add(offsetMinutes, 'minutes');
  }

  return {
    date: isValidDate === true ? dateTime.utc().format(`${campaingConstants.FORMAT_DATE}`) : '',
    time: dateTime.utc().format(`${campaingConstants.FORMAT_TIME}`),
    timeZone,
  };
};

export const getDateTimeFromUtcToLocal = (date, time, timeZone) => {
  let isValidDate = true;
  timeZone = getTimeZone(timeZone);
  let offsetMinutes = getOffsetMinutes(timeZone);
  if (!time) {
    time = campaingConstants.DEFAULT_START_TIME;
  }
  if (moment.utc(time, campaingConstants.FORMAT_TIME, true).isValid() === false) {
    time = campaingConstants.DEFAULT_START_TIME;
  }
  if (moment.utc(date, campaingConstants.FORMAT_DATE, true).isValid() === false) {
    if (moment.utc(date).isValid() === true) {
      date = moment.utc(date).format(campaingConstants.FORMAT_DATE);
    } else {
      isValidDate = false;
      date = moment.utc().format(campaingConstants.FORMAT_DATE);
    }
  }

  let dateTime = moment.utc(`${date} ${time}`, `${campaingConstants.FORMAT_DATE} ${campaingConstants.FORMAT_TIME}`);
  if (offsetMinutes !== 0) {
    dateTime = dateTime.add(offsetMinutes, 'minutes');
  }

  return {
    date: isValidDate === true ? dateTime.format(campaingConstants.FORMAT_DATE) : '',
    dateDisplay: isValidDate === true ? dateTime.format('MM-DD-YYYY') : '',
    time: dateTime.format(`${campaingConstants.FORMAT_TIME}`),
    timeZone,
  };
};

export const getTimeOptions = () => {
  let times = [];
  const addTime = (hour, period) => {
    const formattedHour = `0${hour}`.slice(-2);
    times.push({ value: `${formattedHour}:00 ${period}`, label: `${formattedHour}:00 ${period}` });
    times.push({ value: `${formattedHour}:30 ${period}`, label: `${formattedHour}:30 ${period}` });
  };

  addTime(12, 'AM');
  for (let i = 1; i < 12; i++) {
    addTime(i, 'AM');
  }
  addTime(12, 'PM');
  for (let i = 1; i < 12; i++) {
    addTime(i, 'PM');
  }
  return times;
};

const getTimeZone = (selectTimeZone) => {
  if (selectTimeZone && selectTimeZone !== '') {
    var timeZone = timeZones.find((x) => x.value === selectTimeZone);
    if (timeZone) {
      return timeZone.value;
    }
  }
  return campaingConstants.DEFAULT_TIMEZONE;
};

const getOffsetMinutes = (timeZone) => {
  timeZone = getTimeZone(timeZone);
  let utcSignal = timeZone.substring(4, 5);
  let hours = timeZone.substring(5, 10);
  let minutes = moment.duration(hours).asMinutes();

  if (minutes > 0) {
    if (utcSignal === '-') {
      minutes *= -1;
    }
  }
  return minutes;
};
