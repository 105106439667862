import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Table, Tabs } from 'antd';
import Button from 'components/button/Button';
import {
  _getMarketingCampaignsStatsById,
  _getMarketingCampaignsExecutionsById,
  _getMarketingCampaignsAuditsById,
  _getMarketingCampaignsAuditsTotalsById,
} from 'controllers/schools/schools_controller';
import {
  EyeOutlined,
  StopOutlined,
  WarningOutlined,
  ThunderboltOutlined,
  DownloadOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { StatCard } from 'components/pages/communication-hub/components/stat-card/stat-card';
import ExecutionLineChart from './ExecutionLineChart';
import moment from 'moment';
import localstorage from 'store2';
import './StatsModal.scss';

const StatsModal = ({ schoolId, campaign, onClose }) => {
  const [stats, setStats] = useState({});
  const [executions, setExecutions] = useState([]);
  const [audits, setAudits] = useState([]);
  const [auditsTotals, setAuditsTotals] = useState([]);
  const [showExecutions, setShowExecutions] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    const fetchStats = async () => {
      const data = await _getMarketingCampaignsStatsById({
        school_id: schoolId,
        marketing_campaign_id: campaign.id,
      });
      const statsCurrent = data.reduce((acc, item) => {
        acc[item.event] = parseInt(item.total, 10);
        return acc;
      }, {});
      setStats(statsCurrent);
    };
    fetchStats();
  }, [campaign]);

  useEffect(() => {
    const total_count = Number(campaign.count_sms) + Number(campaign.count_email);

    if (Number(campaign.count_executions) > 0) {
      // Fetch executions
      const fetchExecutions = async () => {
        const data = await _getMarketingCampaignsExecutionsById(schoolId, campaign.id);
        setExecutions(data);
        setShowExecutions(true);
      };
      fetchExecutions();
    } else if (total_count > 0) {
      const fetchAudits = async () => {
        const data = await _getMarketingCampaignsAuditsById(schoolId, campaign.id);
        setAudits(data);
        const dataTotals = await _getMarketingCampaignsAuditsTotalsById(schoolId, campaign.id);
        setAuditsTotals(dataTotals);
      };
      fetchAudits();
    }
  }, [campaign]);

  const executionColumns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => moment(text).format('MM-DD-YYYY'),
    },
    { title: 'Status', dataIndex: 'status', key: 'status', render: (text) => (text === 1 ? 'OK' : 'FAIL') },
    {
      title: 'Audience',
      dataIndex: 'audience',
      key: 'audience',
      render: (_, record) => {
        if (campaign.platform === 'sms') {
          return Number(record.count_sms_fail) + Number(record.count_sms_success);
        }
        return Number(record.count_email_fail) + Number(record.count_email_success);
      },
    },
    {
      title: 'Download',
      align: 'center',
      key: 'download',
      render: (_, record) => (
        <div className="download-icon-container">
          <a className="download-icon" onClick={() => downloadCSV(record.id)}>
            <DownloadOutlined />
          </a>
        </div>
      ),
    },
  ];

  const auditsColumns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => moment(text).format('MM-DD-YYYY'),
    },
    { title: 'Type', dataIndex: 'type', key: 'type', render: (text) => (text === 'email' ? 'Email' : 'SMS') },
    { title: 'To', dataIndex: 'to', key: 'to' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
  ];

  const downloadCSV = async (id) => {
    const token = localstorage.get('idToken');
    const response = await fetch(
      showExecutions
        ? `/api/school/${schoolId}/marketing/campaign/${campaign.id}/executions/${id}?token=${token}`
        : `/api/school/${schoolId}/marketing/campaign/${campaign.id}/audits-report?token=${token}`
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `audience_${id}.csv`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const tabItems = [
    {
      key: '1',
      label: 'Daily Stats',
      children: (
        <ExecutionLineChart
          data={showExecutions ? executions : auditsTotals}
          type={campaign.type === 'advanced' ? 'advanced' : campaign.platform}
        />
      ),
    },
    {
      key: '2',
      label: 'Details',
      children: showExecutions ? (
        <Table columns={executionColumns} dataSource={executions} rowKey="id" />
      ) : (
        <Table columns={auditsColumns} dataSource={audits} rowKey="email" />
      ),
    },
  ];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <Modal
      visible
      onOk={onClose}
      onCancel={onClose}
      okText="Close"
      title="Campaign Statistics"
      className="statsModal"
      width={800}
      footer={[
        <Row justify="start">
          <Button key="submit" onClick={onClose} type="primary">
            Close
          </Button>
          ,
          {activeTab === '2' && !showExecutions && (
            <Button key="download" onClick={() => downloadCSV(campaign.id)} type="default">
              Download
            </Button>
          )}
        </Row>,
      ]}>
      <div className="content">
        <Row className="spacing-mb-16" gutter={[16, 16]}>
          {campaign.type === 'advanced' || campaign.platform === 'email' ? (
            <>
              <Col xs={24} sm={12} lg={6}>
                <StatCard
                  title={
                    <span>
                      Email
                      <br />
                      Sent
                    </span>
                  }
                  value={stats?.count_email_success || 0}
                  icon={SendOutlined}
                  color="primary"
                />
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <StatCard
                  title={
                    <span>
                      Email
                      <br />
                      Fail
                    </span>
                  }
                  value={stats?.count_email_fail || 0}
                  icon={StopOutlined}
                  color="warning"
                />
              </Col>
            </>
          ) : null}
          {campaign.type === 'advanced' || campaign.platform === 'sms' ? (
            <>
              <Col xs={24} sm={12} lg={6}>
                <StatCard
                  title={
                    <span>
                      Sms
                      <br />
                      Sent
                    </span>
                  }
                  value={stats?.count_sms_success || 0}
                  icon={SendOutlined}
                  color="primary"
                />
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <StatCard
                  title={
                    <span>
                      Sms
                      <br />
                      Fail
                    </span>
                  }
                  value={stats?.count_sms_fail || 0}
                  icon={StopOutlined}
                  color="warning"
                />
              </Col>
            </>
          ) : null}
        </Row>
        {Number(stats.count_email_success) > 0 && (
          <Row className="spacing-mb-16" gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={6}>
              <StatCard
                title={
                  <span>
                    Email
                    <br />
                    Opened
                  </span>
                }
                value={stats?.opened || 0}
                total={stats?.sent || 0}
                percentage={stats?.opened_perc || 0}
                icon={EyeOutlined}
                trend={stats?.opened_perc_diff ? `${stats.opened_perc_diff}%` : ''}
                color="primary"
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StatCard
                title={
                  <span>
                    Click
                    <br />
                    Rate
                  </span>
                }
                value={stats?.click || 0}
                total={stats?.sent || 0}
                percentage={Math.round((stats?.click || 0 / stats?.sent || 0) * 100 * 10) / 10}
                icon={ThunderboltOutlined}
                trend={stats?.click_perc_diff ? `${stats.click_perc_diff}%` : ''}
                color="success"
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StatCard
                title={
                  <span>
                    Bounce
                    <br />
                    Rate
                  </span>
                }
                value={stats?.bounced || 0}
                total={stats?.sent || 0}
                percentage={Math.round((stats?.bounced || 0 / stats?.sent || 0) * 100 * 10) / 10}
                icon={StopOutlined}
                trend={stats?.bounced_perc_diff ? `${stats.bounced_perc_diff}%` : ''}
                color="warning"
              />
            </Col>
            <Col xs={24} sm={12} lg={6}>
              <StatCard
                title={
                  <span>
                    Spam
                    <br />
                    Reports
                  </span>
                }
                value={stats?.spam || 0}
                total={stats?.sent || 0}
                percentage={Math.round((stats?.spam || 0 / stats?.sent || 0) * 100 * 10) / 10}
                icon={WarningOutlined}
                trend={stats?.spam_perc_diff ? `${stats.spam_perc_diff}%` : ''}
                color="destructive"
              />
            </Col>
          </Row>
        )}
        <Tabs defaultActiveKey="1" items={tabItems} onChange={handleTabChange} />
      </div>
    </Modal>
  );
};

export default StatsModal;
