import React from 'react';
import EmojiPicker from '../email/EmojiPicker';
import PersonalizationButton from '../email/PersonalizationButton';
import styles from './input-personalization.module.scss';

export const InputPersonalization = ({ label, description, field, value, onChangeParams, loadSchool, school }) => {
  const subjectInput = React.createRef();

  const subjectChange = (payload) => {
    let subjectInputCursorPosition = -1;

    let { key, currentValue, newValue, subjectInput } = payload;
    let input = subjectInput;
    let inputPosition = subjectInputCursorPosition;
    let value = '';
    if (inputPosition === -1 || inputPosition > currentValue.length) {
      if (currentValue.length === 0) {
        inputPosition = 0;
      } else {
        inputPosition = currentValue.length - 1;
      }
    }

    if (inputPosition + 1 === currentValue.length) {
      value = currentValue + newValue;
    } else {
      value =
        currentValue.substring(0, inputPosition) +
        newValue +
        currentValue.substring(inputPosition, currentValue.length);
    }

    inputPosition += newValue.length;

    onChangeParams({ key, value });
    if (input) {
      input.focus();
      setTimeout(() => {
        input.setSelectionRange(inputPosition, inputPosition);
      }, 0);
    }
  };

  return (
    <div className={styles.personalizationWrapper}>
      <input
        type="text"
        ref={subjectInput.current}
        aria-label="Text input with dropdown button"
        value={value}
        placeholder="Enter your subject here"
        onChange={(e) => onChangeParams({ key: field, value: e.target.value })}
      />
      <div className="input-group-append">
        <EmojiPicker
          className={styles.emoji}
          onSelect={(newValue) =>
            subjectChange({
              key: field,
              currentValue: value || '',
              newValue,
              subjectInput: subjectInput.current,
            })
          }
        />
        <PersonalizationButton
          school={school}
          onSelect={(newValue) =>
            subjectChange({
              key: field,
              currentValue: value || '',
              newValue,
              subjectInput: subjectInput.current,
            })
          }
        />
      </div>
    </div>
  );
};
