import { Typography, Flex } from 'antd';
import { IconCircle, Button } from '..';
import { MailOutlined, AppstoreOutlined, MessageOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './template-selector.module.scss';

const iconsAdvanced = {
  email: MailOutlined,
  sms: MessageOutlined,
  hybrid: AppstoreOutlined,
};

export const TemplateSelector = ({ status, templates, onTemplateSelect }) => {
  return (
    <Flex vertical gap="middle">
      {templates.map((template) => (
        <Flex key={template.id} justify="space-between" align="center">
          <Flex gap="middle" align="center">
            {template?.iconKey ? (
              <IconCircle Icon={iconsAdvanced[template.iconKey]} size="small" color="blue" />
            ) : (
              <img src={template.icon} className={styles.icon} alt="campaign icon" />
            )}
            <Typography.Title level={5} style={{ margin: 0 }}>
              {template.name}
            </Typography.Title>
          </Flex>
          <Button
            disabled={status.loading}
            icon={status.loading && <LoadingOutlined />}
            type="default"
            onClick={() => onTemplateSelect(template)}>
            Select
          </Button>
        </Flex>
      ))}
    </Flex>
  );
};
