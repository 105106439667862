import React, { useState, useEffect } from 'react';
import { EyeOutlined, StopOutlined, WarningOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { CampaignsTable, CampaignFilters, StatCard, CalendarRange, CampaignCreationSettings } from '../../components';
import { Button } from '../../components/button/button';
import { Card } from 'components/card/card';
import styles from './advanced-campaigns.module.scss';
import { Row, Col, Select, message } from 'antd';
import dayjs from 'dayjs';
import { _getMarketingCampaigns, _getMarketingCampaignsStatsBySchoolId } from 'controllers/schools/schools_controller';

import { useToggle } from 'hooks/useToggle';
import { endOfDay } from 'date-fns';
import { ArchiveConfirmDialog } from '../../components/archived-confirmation/archived-confirmation';

import { useSelector } from 'react-redux';
import { useCampaign } from '../flow-designer/hooks';
import { onCloseArchiveModal } from 'redux/campaigns/campaigns-reducer';

const { Option } = Select;

export const AdvancedCampaigns = ({ params: { id } }) => {
  const [selectedPeriod, setSelectedPeriod] = useState('7');
  const [statsDates, setStatsDates] = useState({
    startDate: dayjs().subtract(7, 'days').startOf('day'),
    endDate: dayjs().endOf('day'),
  });
  const [previousStatsDates, setPreviousStatsDates] = useState({
    startDate: dayjs().subtract(15, 'days').startOf('day'),
    endDate: dayjs().subtract(8, 'days').endOf('day'),
  });
  const [isCustom, setIsCustom] = useState(false);
  const [stats, setStats] = useState({});

  const [openNewCampaign, toggleNewCampaign] = useToggle(false);
  const [campaigns, setCampaigns] = React.useState([]);
  const [totalCampaigns, setTotalCampaigns] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });
  const [sort, setSort] = React.useState({
    field: 'updated_at',
    direction: 'DESC',
  });
  const [typeFilter, setTypeFilter] = React.useState('all');
  const [statusFilter, setStatusFilter] = React.useState('all');

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
    if (value === 'custom') {
      setIsCustom(true);

      return;
    }

    setIsCustom(false);
    const days = parseInt(value, 10);

    const endDate = dayjs().endOf('day');
    const startDate = dayjs().subtract(days, 'day').startOf('day');
    const previousEndDate = startDate.clone().subtract(1, 'day').startOf('day');
    const previousStartDate = previousEndDate.clone().subtract(days, 'day').startOf('day');

    setStatsDates({ startDate, endDate });
    setPreviousStatsDates({ startDate: previousStartDate, endDate: previousEndDate });
    // Aquí puedes agregar la lógica para actualizar los datos de las estadísticas
  };

  const handleCustomDateChange = (dates) => {
    const [startDate, endDate] = dates;
    setStatsDates({ startDate, endDate });

    const days = endDate.diff(startDate, 'days');
    const previousEndDate = startDate.clone().subtract(1, 'day').endOf('day');
    const previousStartDate = previousEndDate.clone().subtract(days, 'days').startOf('day');

    setPreviousStatsDates({ startDate: previousStartDate, endDate: previousEndDate });
    // Aquí puedes agregar la lógica para actualizar los datos de las estadísticas
  };

  // get the info for the tab
  const onLoadData = async () => {
    if (id) {
      const filters = [];
      if (typeFilter !== 'all') {
        filters.push({
          name: 'platform',
          value: typeFilter,
        });
      }
      if (statusFilter !== 'all') {
        filters.push({
          name: 'status',
          value: statusFilter,
        });
      }
      let _payload = {
        school_id: id,
        page: pagination.page || 1,
        pageSize: pagination.pageSize || 0,
        platform: 'all',
        sortField: sort.field || 'updated_at',
        sortDirection: sort.direction || 'DESC',
        filters: filters || [],
      };

      let data = await _getMarketingCampaigns(_payload);

      setTotalCampaigns(data.pagination.rowCount);
      const _campaigns = data.results.map((campaign) => {
        let mode = campaign.type === 'Advanced' ? 'Advanced' : 'Simple';
        campaign.count_executions = Number(campaign.count_executions);
        campaign.count_email = Number(campaign.count_email);
        campaign.count_sms = Number(campaign.count_sms);

        return {
          id: campaign.id,
          name: campaign.name,
          platform: campaign.platform,
          type: campaign.type,
          mode,
          audience: campaign.audience_language,
          executions:
            campaign.count_executions === 0
              ? campaign.count_email + campaign.count_sms > 0
                ? '--'
                : campaign.count_executions
              : campaign.count_executions,
          sentTo:
            campaign.platform === 'email'
              ? campaign.count_email
              : campaign.platform === 'sms'
              ? campaign.count_sms
              : campaign.count_email + campaign.count_sms,
          lastEdited: campaign.updated_at,
          lastSent: campaign.last_execution,
          status: campaign.status,
          row: campaign,
        };
      });
      setCampaigns(_campaigns);
    }
  };

  const onLoadStatsData = async () => {
    if (id) {
      let _payload = {
        school_id: id,
        start: statsDates.startDate.toString('YYYY-MM-DD 00:00'),
        end: statsDates.endDate.toString('YYYY-MM-DD 23:59'),
      };

      let data = await _getMarketingCampaignsStatsBySchoolId(_payload);
      const statsCurrent = data.reduce((acc, item) => {
        acc[item.event] = parseInt(item.total, 10);
        return acc;
      }, {});

      _payload = {
        school_id: id,
        start: previousStatsDates.startDate.toString('YYYY-MM-DD 00:00'),
        end: previousStatsDates.endDate.toString('YYYY-MM-DD 23:59'),
      };

      let dataPrevious = await _getMarketingCampaignsStatsBySchoolId(_payload);
      const statsPrevious = dataPrevious.reduce((acc, item) => {
        acc[item.event] = parseInt(item.total, 10);
        return acc;
      }, {});

      statsCurrent.opened_perc = statsCurrent.sent
        ? Math.round((statsCurrent.opened / statsCurrent.sent) * 100 * 10) / 10
        : 0;
      statsCurrent.click_perc = statsCurrent.sent
        ? Math.round((statsCurrent.click / statsCurrent.sent) * 100 * 10) / 10
        : 0;
      statsCurrent.bounced_perc = statsCurrent.sent
        ? Math.round((statsCurrent.bounced / statsCurrent.sent) * 100 * 10) / 10
        : 0;
      statsCurrent.spam_perc = statsCurrent.sent
        ? Math.round((statsCurrent.spam / statsCurrent.sent) * 100 * 10) / 10
        : 0;

      statsPrevious.opened_perc = statsPrevious.sent
        ? Math.round((statsPrevious.opened / statsPrevious.sent) * 100 * 10) / 10
        : 0;
      statsPrevious.click_perc = statsPrevious.sent
        ? Math.round((statsPrevious.click / statsPrevious.sent) * 100 * 10) / 10
        : 0;
      statsPrevious.bounced_perc = statsPrevious.sent
        ? Math.round((statsPrevious.bounced / statsPrevious.sent) * 100 * 10) / 10
        : 0;
      statsPrevious.spam_perc = statsPrevious.sent
        ? Math.round((statsPrevious.spam / statsPrevious.sent) * 100 * 10) / 10
        : 0;

      statsCurrent.opened_perc_diff = statsPrevious.opened_perc
        ? Math.round(((statsCurrent.opened_perc - statsPrevious.opened_perc) / statsPrevious.opened_perc) * 100 * 10) /
          10
        : 0;
      statsCurrent.click_perc_diff = statsPrevious.click_perc
        ? Math.round(((statsCurrent.click_perc - statsPrevious.click_perc) / statsPrevious.click_perc) * 100 * 10) / 10
        : 0;
      statsCurrent.bounced_perc_diff = statsPrevious.bounced_perc
        ? Math.round(
            ((statsCurrent.bounced_perc - statsPrevious.bounced_perc) / statsPrevious.bounced_perc) * 100 * 10
          ) / 10
        : 0;
      statsCurrent.spam_perc_diff = statsPrevious.spam_perc
        ? Math.round(((statsCurrent.spam_perc - statsPrevious.spam_perc) / statsPrevious.spam_perc) * 100 * 10) / 10
        : 0;

      const rangeComparison = `${statsDates.startDate.format('MMM DD')} - ${statsDates.endDate.format(
        'MMM D'
      )} vs ${previousStatsDates.startDate.format('MMM DD')} - ${previousStatsDates.endDate.format('MMM DD')}`;

      setStats({
        current: statsCurrent,
        previous: statsPrevious,
        rangeComparison,
      });
    }
  };

  useEffect(() => {
    onLoadStatsData();
  }, [previousStatsDates]);

  useEffect(() => {
    onLoadData();
  }, [typeFilter, statusFilter, pagination, sort]);

  useEffect(() => {
    onLoadData();
  }, []);

  // Archived Advanced Campaigns
  const { campaignRowId } = useSelector((state) => state.campaigns);
  const { archivedAdvanced } = useCampaign();
  const [messageApi, contextHolder] = message.useMessage();

  const handleArchivedType = async (keepRunningDag) => {
    const schoolId = id;
    const response = await archivedAdvanced(schoolId, campaignRowId, keepRunningDag);
    if (response.success) {
      onLoadData();
      messageApi.open({
        type: 'success',
        content: `Campaign ${campaignRowId} archived successfully`,
      });
      onCloseArchiveModal();
    } else {
      messageApi.open({
        type: 'error',
        content: `Failed to archive campaign ${campaignRowId}`,
      });
    }
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <h4 style={{ marginBottom: 0 }} className={`heading-4 ${styles.heading}`}>
            Campaigns Performance
          </h4>
        </Col>
        <Col style={{ marginBottom: '.75rem' }}>
          <Select
            id="periodSelect"
            className={styles.periodSelect}
            value={selectedPeriod}
            onChange={handlePeriodChange}>
            <Option value="">Select a period</Option>
            <Option value="7">Last 7 days</Option>
            <Option value="30">Last 30 days</Option>
            <Option value="90">Last 90 days</Option>
            <Option value="180">Last 6 months</Option>
            <Option value="365">Last year</Option>
            <Option value="custom">Custom</Option>
          </Select>
          {isCustom && (
            <CalendarRange
              defaultValue={[statsDates.startDate, statsDates.endDate]}
              format={'MMM D, YYYY'}
              onChange={handleCustomDateChange}
            />
          )}
        </Col>
      </Row>

      <Row className="spacing-mb-16" gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <StatCard
            title="Emails Opened"
            value={stats?.current?.opened || 0}
            total={stats?.current?.sent || 0}
            percentage={stats?.current?.opened_perc || 0}
            icon={EyeOutlined}
            trend={(stats?.current?.opened_perc_diff || 0) !== 0 ? `${stats?.current?.opened_perc_diff}%` : ''}
            comparison={stats?.rangeComparison || ''}
            color="primary"
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <StatCard
            title="Click Rate"
            value={stats?.current?.click || 0}
            total={stats?.current?.sent || 0}
            percentage={stats?.current?.click_perc || 0}
            icon={ThunderboltOutlined}
            trend={(stats?.current?.click_perc_diff || 0) !== 0 ? `${stats?.current?.click_perc_diff}%` : ''}
            comparison={stats?.rangeComparison || ''}
            color="success"
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <StatCard
            title="Bounce Rate"
            value={stats?.current?.bounced || 0}
            total={stats?.current?.sent || 0}
            percentage={stats?.current?.bounced_perc || 0}
            icon={StopOutlined}
            trend={(stats?.current?.bounced_perc_diff || 0) !== 0 ? `${stats?.current?.bounced_perc_diff}%` : ''}
            comparison={stats?.rangeComparison || ''}
            color="warning"
          />
        </Col>
        <Col xs={24} sm={12} lg={6}>
          <StatCard
            title="Spam Reports"
            value={stats?.current?.spam || 0}
            total={stats?.current?.sent || 0}
            percentage={stats?.current?.spam_perc || 0}
            icon={WarningOutlined}
            trend={(stats?.current?.spam_perc_diff || 0) !== 0 ? `${stats?.current?.spam_perc_diff}%` : ''}
            comparison={stats?.rangeComparison || ''}
            color="destructive"
          />
        </Col>
      </Row>
      <Row className="spacing-mb-16">
        <Col span={24}>
          <CampaignFilters
            typeFilter={typeFilter}
            statusFilter={statusFilter}
            setStatusFilter={(value) => {
              setStatusFilter(value);
            }}
            setTypeFilter={(value) => {
              setTypeFilter(value);
            }}>
            <Button onClick={toggleNewCampaign}>Create Campaign</Button>
          </CampaignFilters>
        </Col>
      </Row>
      <Row className="spacing-mb-16">
        <Col span={24}>
          <Card className={styles.cardTableCampains}>
            <CampaignsTable
              totalCampaigns={totalCampaigns}
              campaigns={campaigns}
              onChange={onLoadData}
              setPagination={setPagination}
              setSort={setSort}
              school_id={id}
            />
          </Card>
        </Col>
      </Row>

      {/* Modal Layers Components */}
      <CampaignCreationSettings schoolId={id} isVisible={openNewCampaign} toggleVisible={toggleNewCampaign} />
      {contextHolder}
      <ArchiveConfirmDialog handleArchivedType={handleArchivedType} schoolId={id} />
    </>
  );
};
