import { _addMarketingCampaign, _updateMarketingCampaignStatus } from 'controllers/schools/schools_controller';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaign, updateCampaignValue, setCampaignParam, PARSE_PARAMS } from 'redux/campaigns/campaigns-reducer';

export const useCampaign = () => {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaigns.campaign);
  const loading = useSelector((state) => state.campaigns.loading);
  const error = useSelector((state) => state.campaigns.error);

  const initCampaign = (schoolId, campaignId) => dispatch(getCampaign(schoolId, campaignId));

  const updateValue = ({ key, value }) => {
    dispatch(updateCampaignValue({ key, value }));
  };

  const setParam = ({ key, value }) => {
    dispatch(setCampaignParam({ key, value }));
  };

  const getParamValue = (key) => {
    const param = campaign.params?.find((param) => param.key === key);
    if (param) return param.value;
  };

  const updateCampaign = async (schoolId) => {
    const campaignToUpdate = { ...campaign };
    delete campaignToUpdate.created_at;
    delete campaignToUpdate.updated_at;
    delete campaignToUpdate.status;

    campaignToUpdate.params = campaignToUpdate.params.map((item) => {
      if (PARSE_PARAMS.includes(item.key)) {
        return {
          key: item.key,
          value: JSON.stringify(item.value),
        };
      }

      return {
        key: item.key,
        value: item.value || '',
      };
    });

    try {
      let update = await _addMarketingCampaign(schoolId, campaignToUpdate);
      return { success: true, update };
    } catch (error) {
      console.log('updateCampaign', error);
      return { success: false, error };
    }
  };

  const setCampaignStatus = async (schoolId, campaignId, status, keepRunningDag) => {
    try {
      let updateStatus = await _updateMarketingCampaignStatus(schoolId, campaignId, status, keepRunningDag);
      return { success: true, updateStatus };
    } catch (error) {
      console.log('setCampaignStatus', error);
      return { success: false, error };
    }
  };

  const archivedAdvanced = async (schoolId, campaignId, keepRunningDag) => {
    return await setCampaignStatus(schoolId, campaignId, 'Archived', keepRunningDag);
  };

  const handleTailBranch = ({ placeholderId, status }) => {
    // status = 'placeholderNode' || 'endNode'
    const tails = getParamValue('tails') || [];
    const index = tails.findIndex((tail) => tail.placeholderId === placeholderId);
    if (index !== -1) {
      tails[index].status = status;
    } else {
      tails.push({ placeholderId, status });
    }

    setParam({ key: 'tails', value: tails });
  };

  return {
    initCampaign,
    campaign,
    loading,
    error,
    updateCampaignValue: updateValue,
    getParamValue,
    setCampaignParam: setParam,
    setCampaignStatus,
    updateCampaign,
    handleTailBranch,
    archivedAdvanced,
    isEditionDisabled: Boolean(campaign.dag_id),
  };
};

export const getGlobalParams = (params) => {
  const globalParams = [
    '{{logo}}',
    '{{primary_color}}',
    '{{secondary_color}}',
    '{{facebook}}',
    '{{instagram}}',
    '{{twitter}}',
    '{{youtube}}',
    '{{from_email}}',
    '{{from_name}}',
  ];
  return params?.filter((param) => globalParams.includes(param.key));
};
