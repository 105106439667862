import React, { Component, Fragment } from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

export default class EmojiPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleMenu = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('click', this.onClickOutsideHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClickOutsideHandler);
  }

  onClickOutsideHandler = (event) => {
    if (this.toggleMenu.current && !this.toggleMenu.current.contains(event.target)) this.onHideMenu();
  };

  onHideMenu = () => this.setState({ showMenu: false });

  onSelect = (value) => {
    this.props.onSelect(value.native);
  };

  render() {
    const { className } = this.props;
    const { showMenu } = this.state;
    const _style = {
      paddingLeft: '5px',
      borderColor: '#E7E7E7 !important',
      width: '35px',
      height: '35px',
    };
    return (
      <>
        <div className="emoji-button" ref={this.toggleMenu} style={{ position: 'relative' }}>
          <button
            className={className || 'border border-left-0'}
            onClick={() => this.setState({ showMenu: true })}
            style={{ ..._style }}>
            🙂
          </button>
          {showMenu && (
            <div className="actions-content-menu" style={{ position: 'absolute', top: '50px' }}>
              <Picker title="Pick your emoji…" emoji="point_up" onSelect={this.onSelect} />
            </div>
          )}
        </div>
      </>
    );
  }
}
