import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { _getCountForMarketingCampaigns, _getLeadStatuses } from 'controllers/leads/leads_controller';
import { getResponseValue } from 'util/validate';
import { useCampaign } from '../../flow-designer/hooks';
import { Radio, Typography, Divider, Input, Tooltip } from 'antd';
import { ToolSegmentation } from 'components/pages/campaigns/components/tool-segmentation/tool-segmentation';
import campaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AudienceChoose } from './audience-choose';

export const AdvancedCampaignsAudience = ({ schoolId, isTriggered, runOnPublish }) => {
  const { campaign, updateCampaignValue, getParamValue, setCampaignParam } = useCampaign();
  const { currentSchool } = useSelector((state) => state.school);
  const [campaignName, setCampaignName] = useState(campaign.name || '');
  const [countLeads, setCountLeads] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);
  const audienceLanguage = campaign.audience_language || 'all';

  // get leads count
  const loadCountForMarketingCampaigns = async () => {
    const clientTimezoneOffset = new Date().getTimezoneOffset() / 60;
    _getCountForMarketingCampaigns(schoolId, clientTimezoneOffset * -1).then(({ results }) => {
      setCountLeads(results);
    });
  };
  const loadLeadStatus = async () => {
    _getLeadStatuses(schoolId)
      .then((leadStatuses) => getResponseValue(leadStatuses))
      .then((leadStatuses) => setLeadStatuses(leadStatuses))
      .catch((error) => console.log('Error getting lead statuses', error));
  };
  useEffect(() => {
    if (schoolId) {
      loadCountForMarketingCampaigns();
      loadLeadStatus();
    }
  }, []);

  // get campaign params & segmentation value
  const segmentationValue = JSON.parse(getParamValue(campaingConstants.SEGMENTATION) || '[]');
  const sentToValue = getParamValue(campaingConstants.SENT_TO_KEY);
  const leadStatusId = parseInt(getParamValue(campaingConstants.LEAD_STATUS_ID_KEY), 10);

  // set parameter value
  const setParameterValue = (campaign, key, value) => {
    const idx = campaign.params?.findIndex((p) => p.key === key);
    if (idx > -1) {
      campaign.params[idx].value = value;
    } else {
      campaign.params?.push({ key, value });
    }
  };
  // handle segmentation value
  const handleSegmentationValue = (segmentationValues) => {
    const transformedData = segmentationValues.map(
      (item) => `${item.field}:${item.type === 'array' ? item.values.join(',') : item.values}`
    );
    const stringData = JSON.stringify(transformedData);
    setParameterValue(campaign, '{{segmentation}}', stringData);
    // update the campaign state
    updateCampaignValue('params', campaign.params);
  };

  const onChangeValueLegagy = (key, value) => {
    if (key === 'status') {
      setParameterValue(campaign, campaingConstants.SENT_TO_KEY, value);
    }
    if (key === 'lead_status_id') {
      setParameterValue(campaign, campaingConstants.LEAD_STATUS_ID_KEY, value);
    }
    // update the campaign state
    updateCampaignValue('params', campaign.params);
  };

  useEffect(() => {
    // Sync local state if campaign.name changes
    setCampaignName(campaign.name || '');
  }, [campaign.name]);

  const onNameChange = (e) => {
    setCampaignName(e.target.value);
    updateCampaignValue({ key: 'name', value: e.target.value });
  };

  const onIncludeAudience = (value) => {
    setCampaignParam({ key: 'run_on_publish', value });
  };

  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <label style={{ display: 'block', marginBottom: 8, fontWeight: 600 }}>
          Campaign Name{' '}
          <Tooltip
            color="#006b8f"
            title="Give your campaign a descriptive name. This will appear in you campaigns list and help you identify it later">
            <InfoCircleOutlined />
          </Tooltip>
        </label>
        <Input placeholder="Enter campaign name" value={campaignName} onChange={(e) => onNameChange(e)} />
      </div>
      <Divider className="spacing-mb-16" />
      <Typography.Title level={5}>Audience Language</Typography.Title>
      <Radio.Group
        name="language"
        value={campaign?.audience_language}
        onChange={({ target }) => updateCampaignValue({ key: 'audience_language', value: target.value })}
        options={[
          { value: 'all', label: 'All Languages' },
          { value: 'en', label: 'English' },
          { value: 'es', label: 'Spanish' },
        ]}
      />
      {currentSchool && campaign && (
        <>
          <Divider className="spacing-my-16" />
          <ToolSegmentation
            school={currentSchool || {}}
            leadCountForMarketingCampaigns={countLeads}
            leadStatuses={leadStatuses}
            audienceLanguage={audienceLanguage}
            handleSegmentationValue={handleSegmentationValue}
            savedParams={segmentationValue}
            onChangeValueLegagy={onChangeValueLegagy}
            prevSentToValue={sentToValue}
            prevLeadStatusId={leadStatusId}
            isAdvancedCampaign={campaign.type === 'Advanced'}
          />
        </>
      )}
      {isTriggered && <AudienceChoose runOnPublish={runOnPublish} onIncludeAudience={onIncludeAudience} />}
    </>
  );
};
