import { NodeWrapper } from '.';
import styles from '../flow-designer.module.scss';
import { MailOutlined } from '@ant-design/icons';
import campaingConstants from 'components/pages/campaigns/utils/CampaingConstants';

export const NodeEmail = ({ data, type, id, positionAbsoluteX, positionAbsoluteY }) => {
  const subject = campaingConstants.SUBJECT_KEY;
  const previewText = data.find((item) => item.key === subject).value || 'Start your message';

  return (
    <NodeWrapper nodeType={type} isBranchable id={id} position={{ x: positionAbsoluteX, y: positionAbsoluteY }}>
      <NodeWrapper.Header id={id} Icon={MailOutlined}>
        Send Email
      </NodeWrapper.Header>
      <ContentPreview>{previewText}</ContentPreview>
    </NodeWrapper>
  );
};

export const ContentPreview = ({ children }) => {
  return (
    <div className={styles.emailNode}>
      <div className={styles.previewText}>{children}</div>
    </div>
  );
};
