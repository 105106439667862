import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { useStatus } from 'hooks/useStatus';
import { onCloseSaveModal } from 'redux/campaigns/campaigns-reducer';
import { Flex, Modal, notification, Typography, Divider, Tag, Alert } from 'antd';
import { CampaignBuilder } from '../..';
import { Button } from '../../../components';
import { useCampaign } from '../hooks';
import campaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import { getDateTimeFromUtcToLocal } from '../utils/schedule-utils';

export const SaveUpdatedCampaign = ({ schoolId, campaignId }) => {
  const { isModalSaveOpen, leadCount } = useSelector((state) => state.campaigns);

  const { campaign, updateCampaign, setCampaignStatus, getParamValue } = useCampaign();

  const isPublish = useMemo(() => campaign.status === 'Active', [campaign]);

  const isTrigger = getParamValue('{{trigger}}') === campaingConstants.TRIGGER_ADD_SCHOLA_SYSTEM_VALUE;
  const runOnPublish = getParamValue('run_on_publish');

  const { status, onLoading, onSuccess, onError } = useStatus();

  const handleUpdateCampaign = async (status) => {
    const updateStatus = !isPublish ? 'Active' : undefined;
    console.log({ updateStatus });

    let feedback = {
      message: 'Campaign Saved',
      description: 'The campaign has been updated successfully.',
    };
    try {
      onLoading();
      const updateCampaignData = await updateCampaign(schoolId);

      if (updateCampaignData.success && status) {
        await setCampaignStatus(schoolId, campaignId, status);
        feedback.message = `Campaign ${status} Successfully`;
      }

      notification.success(feedback);
      onSuccess();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'The campaign had an error.',
      });
    }
  };

  const [spinner, setSpinner] = useState('');

  const onInactiveCampaign = () => {
    setSpinner('pause');
    handleUpdateCampaign(isPublish ? 'Paused' : undefined);
  };

  const onPublishCampaign = () => {
    setSpinner('publish');
    handleUpdateCampaign(!isPublish ? 'Active' : undefined);
  };

  useEffect(() => {
    if (status.success) {
      onCloseSaveModal();
      goToDashboard();
    }
  }, [status]);

  const goToDashboard = () => browserHistory.push(`/admin/v2/schools/${schoolId}/communication-hub/campaigns-center`);

  const schedule = useMemo(() => {
    if (campaign?.params) {
      const isTriggered = getParamValue('{{trigger}}') === campaingConstants.TRIGGER_ADD_SCHOLA_SYSTEM_VALUE;
      const scheduleParam = getParamValue('{{schedule}}');

      if (scheduleParam) {
        const scheduleTime = JSON.parse(scheduleParam);
        const localTime = getDateTimeFromUtcToLocal(
          scheduleTime.start_date,
          scheduleTime.start_time,
          scheduleTime.selectTimeZone
        );

        return { isTriggered, ...localTime };
      }

      return null;
    }
  }, [campaign.params]);

  return (
    <Modal
      open={isModalSaveOpen}
      onCancel={onCloseSaveModal}
      width={500}
      title="Confirm and Save"
      className="modal-confirm-and-save"
      footer={[
        <Button key="cancel" type="default" disabled={status.loading} onClick={onCloseSaveModal}>
          Cancel
        </Button>,
        <Button
          key="pause"
          type="default"
          disabled={status.loading && spinner !== 'pause'}
          loading={status.loading && spinner === 'pause'}
          onClick={onInactiveCampaign}>
          {isPublish ? 'Pause Campaign' : 'Save as Draft'}
        </Button>,
        <Button
          key="publish"
          disabled={status.loading && spinner !== 'publish'}
          loading={status.loading && spinner === 'publish'}
          onClick={onPublishCampaign}>
          {isPublish ? 'Save Changes' : 'Confirm Publication'}
        </Button>,
      ]}>
      <Divider orientation="left" orientationMargin="0">
        <Flex justify="space-between">
          <span style={{ marginRight: '1rem' }}>Current Campaign Status:</span>
          <CampaignBuilder.Status status={campaign?.status} />
        </Flex>
      </Divider>

      <div>
        <Alert
          message="Important"
          description="Once the campaign is Active, you will not be able to add or remove nodes. Only the content inside them can be edited."
          type="warning"
        />
        <div style={{ margin: '2rem 0' }}>
          <DetailItem label="Language" value={campaign?.audience_language} />
          <DetailItem label="Platform" value={campaign?.platform} />
          {/* only show in scheduled type, and current audience */}
          {(!isTrigger || (isTrigger && JSON.parse(runOnPublish || false))) && (
            <DetailItem label="Leads Targeting" value={leadCount} />
          )}
        </div>
      </div>
    </Modal>
  );
};

const DetailItem = ({ label, value }) => (
  <>
    <Typography.Text>{label}: </Typography.Text>
    <Tag bordered={false} color={value ? 'processing' : 'error'} style={{ textTransform: 'capitalize' }}>
      {value}
    </Tag>
  </>
);
