import { useMemo } from 'react';
import { Flex, Typography, Tooltip } from 'antd';
import {
  MailOutlined,
  MessageOutlined,
  FieldTimeOutlined,
  BranchesOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import styles from '../flow-designer.module.scss';
import { useCampaign, useUpdateNode } from '../hooks';

const nodeTypes = [
  { id: 'email', type: 'emailNode', label: 'Send Email', icon: MailOutlined },
  { id: 'sms', type: 'smsNode', label: 'Send SMS', icon: MessageOutlined },
];
const nodeBase = [
  { id: 'timer', tag: 'LOGIC', type: 'timerNode', label: 'Wait', icon: FieldTimeOutlined },
  { id: 'condition', tag: 'TIMING', type: 'conditionNode', label: 'Condition', icon: BranchesOutlined },
];

export const SelectorNode = ({ campaignType, onNodeClick, isEmptyNode, selectedNode }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const { isEditionDisabled } = useCampaign();

  const { handleChangeToPlaceholder } = useUpdateNode();

  const nodeOptions = useMemo(() => {
    if (campaignType === 'hybrid') return nodeTypes;

    return nodeTypes.filter((node) => campaignType === node.id);
  }, [campaignType]);

  const { Paragraph, Text } = Typography;

  return (
    <div>
      <Paragraph>
        Available Flow Nodes{' '}
        <Tooltip
          color="#006b8f"
          title="Add nodes to build your campaign sequence. Simply drag and  drop or click to insert email, SMS, and timing action into your flow.">
          <InfoCircleOutlined />
        </Tooltip>
      </Paragraph>
      {selectedNode?.type === 'endNode' ? (
        <NodeButton
          isEditionDisabled={isEditionDisabled}
          Icon={PlusCircleOutlined}
          onNodeClick={() => handleChangeToPlaceholder(selectedNode.id)}>
          Continue Flow
        </NodeButton>
      ) : (
        <Flex vertical gap="small">
          <Text type="secondary">ACTIONS</Text>
          {nodeOptions.map((node) => {
            return (
              <NodeButton
                key={node.id}
                node={node}
                Icon={node.icon}
                onNodeClick={() => onNodeClick(node.type)}
                onDragStart={onDragStart}
                isEditionDisabled={isEditionDisabled}>
                {node.label}
              </NodeButton>
            );
          })}
          {!isEmptyNode &&
            nodeBase.map((node) => {
              return (
                <div key={node.id}>
                  <Text type="secondary">{node.tag}</Text>
                  <NodeButton
                    node={node}
                    Icon={node.icon}
                    onNodeClick={() => onNodeClick(node.type)}
                    onDragStart={onDragStart}
                    isEditionDisabled={isEditionDisabled}>
                    {node.label}
                  </NodeButton>
                </div>
              );
            })}
        </Flex>
      )}
    </div>
  );
};

const NodeButton = ({ node, onNodeClick, Icon, children, onDragStart, isEditionDisabled }) => {
  return (
    <button
      type="default"
      onClick={onNodeClick}
      className={styles.nodeButton}
      draggable
      disabled={isEditionDisabled}
      onDragStart={(e) => onDragStart(e, node.type)}>
      <Icon style={{ color: '#0099cc' }} />
      <span>{children}</span>
    </button>
  );
};
