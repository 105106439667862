import React, { useMemo } from 'react';
import { NodeWrapper } from '.';
import { ThunderboltOutlined } from '@ant-design/icons';
import { Select, Typography, Flex, DatePicker } from 'antd';
import { useCampaign, useUpdateNode } from '../hooks';
import styles from './node-trigger.module.scss';
import dayjs from 'dayjs';
import { getTimeFromLocalToUtc, getTimeOptions, timeZones } from '../utils/schedule-utils';

const options = [
  { value: 'add_schola_system', label: 'Real-Time: Start Immediately' },
  { value: 'none', label: 'Schedule for Later' },
];

export const NodeTrigger = ({ data, id }) => {
  const times = useMemo(() => getTimeOptions(), []);
  const { onChangeNode } = useUpdateNode();

  const { setCampaignParam } = useCampaign();

  const handleTrigger = (value) => {
    onChangeNode(id, 'trigger', value);

    setCampaignParam({ key: '{{trigger}}', value });
  };

  const onChangeSchedule = (key, value) => {
    onChangeNode(id, key, value);

    const schedule = {
      ...data,
      [key]: value,
    };
    const timeFromLocalToUTC = getTimeFromLocalToUtc(schedule.start_date, schedule.start_time, schedule.selectTimeZone);

    setCampaignParam({
      key: '{{schedule}}',
      value: JSON.stringify({
        start_date: timeFromLocalToUTC.date,
        start_time: timeFromLocalToUTC.time,
        selectTimeZone: timeFromLocalToUTC.timeZone,
      }),
    });
  };

  return (
    <NodeWrapper isFirstNode id={id} className={styles.trigerNode}>
      <NodeWrapper.Header id={id} Icon={ThunderboltOutlined} hideActions>
        Trigger
      </NodeWrapper.Header>
      <Flex vertical gap="middle">
        <div>
          <Typography.Paragraph className={styles.triggerText}>When should this campaign start?</Typography.Paragraph>
          <Select
            className="nodrag"
            value={data.trigger}
            onChange={handleTrigger}
            options={options}
            style={{
              width: '100%',
            }}
            size="large"
          />
          <div className={styles.triggerDescription}>
            <Typography.Text>
              {data.trigger !== 'none'
                ? 'New leads will automatically enter this campaign as soon as they match your audience filters.'
                : 'Send to all matching leads at your specified date and time.'}
            </Typography.Text>
          </div>

          {data.trigger === 'none' && (
            <>
              <div className={styles.schedule}>
                <DatePicker
                  onChange={(date, dateString) => onChangeSchedule('start_date', dateString)}
                  defaultValue={dayjs(data.start_date, 'YYYY-MM-DD')}
                />
                <Select
                  className="nodrag"
                  onChange={(value) => onChangeSchedule('start_time', value)}
                  options={times}
                  value={data.start_time}
                />
              </div>
              <Select
                className="nodrag"
                onChange={(value) => onChangeSchedule('selectTimeZone', value)}
                options={timeZones}
                value={data.selectTimeZone}
                style={{
                  width: '100%',
                }}
              />
            </>
          )}
        </div>
      </Flex>
    </NodeWrapper>
  );
};
