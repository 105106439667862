import { useEffect, useMemo, useState } from 'react';
import { Typography, Row, Col, Flex, Tooltip, Modal } from 'antd';
import CampaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import { InputPersonalization } from 'components/pages/campaigns/components/shared/input-personalization';
import { CTAcampaign } from 'components/pages/campaigns/components/shared/cta-campaign';
import { LAYOUTS } from 'components/pages/campaigns/components/email/Layouts';
import { Signature } from 'components/pages/campaigns/components/shared/signature';
import CDPreview from 'components/pages/campaigns/components/email/CDPreview';
import EmailMainBody from 'components/pages/campaigns/components/email/EmailMainBody';
import { InputCampaign, SectionTitle } from 'components/pages/campaigns/components/shared/input-campaign';
import { useUpdateNode } from '../../hooks';
import { useNodesData } from '@xyflow/react';
import styles from './edit-node-content.module.scss';
import EmailImage from 'components/pages/campaigns/components/email/EmailImage';
import { Button } from 'components/pages/communication-hub/components';
import { onCloseEditModal } from 'redux/campaigns/campaigns-reducer';
import { _sendTestMarketingCampaign } from 'controllers/schools/schools_controller';
import ToastNotification from 'components/toast-notification/ToastNotification';
import PhoneInput from 'antd-phone-input';
import { getProfile } from 'util/auth';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

export const EditNodeContent = ({ campaign, currentSchool }) => {
  const [layout] = LAYOUTS;
  const { SUBJECT_KEY, EMAIL_KEY } = CampaingConstants;
  const {
    HEADER_KEY,
    CTA_TYPE_KEY,
    NAME_KEY,
    CTA_CUSTOM_BTN_TEXT_KEY,
    CTA_CUSTOM_URL_KEY,
    SIGNATURE_TYPE_KEY,
    SIGNATURE_LABEL_KEY,
  } = layout.keys;

  const { isModalOpen, nodeId } = useSelector((state) => state.campaigns);

  const currentNode = useNodesData(nodeId);
  const isEmail = currentNode?.type === 'emailNode';

  const { updateNodeData } = useUpdateNode();
  const [tempData, setTempData] = useState([]);
  const [showSendTest, setShowSendTest] = useState(false);
  const [to, setTo] = useState('');
  const [toast, setToast] = useState(null);

  // initialize node data
  useEffect(() => {
    if (nodeId) {
      setTempData(currentNode.data);
    }
  }, [nodeId]);

  const getNodeParamValue = (key) => {
    const value = tempData.find((item) => item.key === key)?.value;
    if (!value) return '';
    return value;
  };

  const onChangeParams = (payload) => {
    setTempData((prevData) => {
      const itemExists = prevData.some((item) => item.key === payload.key);
      if (itemExists) {
        return prevData.map((item) => (item.key === payload.key ? { ...item, value: payload.value } : item));
      }
      return [...prevData, { key: payload.key, value: payload.value }];
    });
  };

  const onRemoveParam = (key) => {
    setTempData((prevData) => prevData.filter((item) => item.key !== key));
  };

  const campaignModified = useMemo(() => {
    return {
      ...campaign,
      params: tempData,
    };
  }, [campaign, tempData]);

  const validateInputs = () => {
    const basicKeys = [
      { key: SUBJECT_KEY, name: 'Subject' },
      { key: EMAIL_KEY, name: 'Reply To' },
    ];
    const errors = [];

    if (isEmail) {
      basicKeys.forEach(({ key, name }) => {
        if (!getNodeParamValue(key)) {
          errors.push(`${name} is required`);
        }
      });
    }

    if (getNodeParamValue(CTA_TYPE_KEY) === 'custom') {
      if (!getNodeParamValue(CTA_CUSTOM_BTN_TEXT_KEY)) {
        errors.push(`Custom CTA Button is required`);
      }
      if (!getNodeParamValue(CTA_CUSTOM_URL_KEY)) {
        errors.push(`Custom URL Link is required`);
      }
    }

    if (getNodeParamValue(SIGNATURE_TYPE_KEY) === 'custom') {
      if (!getNodeParamValue(SIGNATURE_LABEL_KEY)) {
        errors.push(`Custom Signature Label is required`);
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
    };
  };

  const [error, setError] = useState('');

  const onCleanError = () => {
    if (error) {
      setError('');
    }
  };

  const onCloseEdition = () => {
    onCleanError();
    onCloseEditModal();
  };

  const onSave = () => {
    const validation = validateInputs();
    if (validation.isValid) {
      updateNodeData(nodeId, tempData);
      onCloseEdition();
    } else {
      setError(validation.errors.join(', '));
    }
  };

  const onSendTest = () => {
    const profile = getProfile();
    if (isEmail) setTo(profile.email || '');
    setShowSendTest(true);
  };

  const renderTest = () => {
    const sendTest = () => {
      let _campaign = JSON.parse(JSON.stringify(campaignModified));
      _campaign.platform = isEmail ? 'email' : 'sms';
      _campaign.type = 'Regular Mass';
      console.log(_campaign);

      let payload = {
        marketingCampaign: _campaign,
        lead: {
          phone: to,
          email: to,
        },
      };

      _sendTestMarketingCampaign(currentSchool.id, payload).then((res) => {
        setShowSendTest(false);
        if (res.error) {
          setToast({
            type: 'error',
            message:
              'Oops! There seems to be a slight problem on our end. Please try again in a few minutes. If this problem continues, please contact our support team to describe the issue.',
          });
        } else if (res.success === false) {
          setToast({
            type: 'error',
            message: `Unsuccessful send, try with another ${isEmail ? 'email' : 'phone number'}`,
          });
        } else {
          setToast({
            type: 'success',
            message: `Sent successfully to '${to}'.`,
          });
        }
      });
    };

    const close = () => {
      setShowSendTest(false);
    };

    return (
      <Modal
        open={showSendTest}
        className="sms-layouts-modal"
        title="Send Test"
        onCancel={close}
        footer={[
          <Button key="close" type="default" onClick={close}>
            Cancel
          </Button>,
          <Button key="send" disabled={to === ''} onClick={sendTest}>
            Send
          </Button>,
        ]}>
        <div className="content">
          <div className="d-flex flex-wrap" style={{ alignItems: 'center' }}>
            <label className="font-16 font-400">{isEmail ? 'Email' : 'Phone'}:</label>
            {!isEmail && (
              <PhoneInput
                value={to}
                onChange={(e) => {
                  setTo(`+${e.countryCode}${e.areaCode}${e.phoneNumber}`);
                }}
                country="us"
                onlyCountries={['us', 'mx']}
                placeholder="Enter a valid phone number"
                enableArrow
                distinct
              />
            )}
            {isEmail && (
              <input
                type="tel"
                value={to}
                placeholder={`Enter a valid email`}
                onChange={(e) => setTo(e.target.value)}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  };

  const renderToast = () => {
    return (
      <>
        {toast && (
          <ToastNotification type={toast.type} message={toast.message} time={4000} onClose={() => setToast(null)} />
        )}
      </>
    );
  };
  const textContentToolTip = (
    <p>
      Update your logo, social media links and colors in the{' '}
      <a
        style={{ color: 'lightblue', textDecoration: 'underline' }}
        target="_blank"
        rel="noreferrer"
        href={`/admin/v2/schools/${currentSchool.id}/profile`}>
        School Profile settings
      </a>
      .
    </p>
  );
  return (
    <Modal
      open={isModalOpen}
      onCancel={onCloseEdition}
      width={950}
      footer={[
        <Button key="cancel" type="default" onClick={onCloseEdition}>
          Cancel
        </Button>,
        <Button key="test" id="test" onClick={onSendTest}>
          Send Test
        </Button>,
        <Button key="save" id="save" onClick={onSave}>
          Save
        </Button>,
      ]}>
      <Row className={styles.layout}>
        <Col span={13} className={styles.details}>
          <Title>{isEmail ? 'Email' : 'SMS'} Details</Title>
          <GroupInputs>
            {isEmail && (
              <>
                <div>
                  <SectionTitle>Subject</SectionTitle>
                  <InputPersonalization
                    field={SUBJECT_KEY}
                    value={getNodeParamValue(SUBJECT_KEY)}
                    onChangeParams={onChangeParams}
                    school={currentSchool}
                  />
                </div>
                <div>
                  <SectionTitle>Reply To</SectionTitle>
                  <InputCampaign
                    placeholder="Enter a valid email"
                    id={EMAIL_KEY}
                    value={getNodeParamValue(EMAIL_KEY)}
                    onChange={onChangeParams}
                  />
                </div>
                <div>
                  <SectionTitle>Header</SectionTitle>
                  <InputPersonalization
                    field={HEADER_KEY}
                    value={getNodeParamValue(HEADER_KEY)}
                    onChangeParams={onChangeParams}
                  />
                </div>
              </>
            )}

            <EmailMainBody
              campaign={campaignModified}
              platform={isEmail ? 'email' : 'sms'}
              validatePlanInFrontend={false}
              isPremium
              school={currentSchool}
              layout={layout}
              onChangeParams={onChangeParams}
            />

            <div>
              <SectionTitle>Call to Action</SectionTitle>
              <CTAcampaign
                layout={layout}
                getParamValue={getNodeParamValue}
                onChangeParams={onChangeParams}
                isEmail={isEmail}
              />
            </div>

            <div>
              <SectionTitle>Signature</SectionTitle>
              <Signature
                school={{ name: getNodeParamValue(NAME_KEY) }}
                layout={layout}
                getParamValue={getNodeParamValue}
                onChangeParams={onChangeParams}
              />
            </div>
            <EmailImage
              campaign={campaignModified}
              school_id={currentSchool.id}
              layout={layout}
              onChangeParams={onChangeParams}
              onRemoveParam={onRemoveParam}
            />
          </GroupInputs>
        </Col>

        <Col span={11} className={styles.content}>
          <Flex justify="space-between" align="center">
            <Title>
              {isEmail ? 'Email' : 'SMS'} Content{' '}
              {isEmail ? (
                <Tooltip color="#006b8f" placement="top" title={textContentToolTip}>
                  <InfoCircleOutlined style={{ color: '#0099cc' }} />
                </Tooltip>
              ) : null}
            </Title>
          </Flex>

          <CDPreview
            school={currentSchool}
            campaign={campaignModified}
            platform={isEmail ? 'email' : 'sms'}
            layout={layout}
          />
        </Col>
      </Row>
      {error && (
        <div className={styles.actions}>
          <Typography.Paragraph type="danger">
            Please fill in all required fields to save correctly: {error}
          </Typography.Paragraph>
        </div>
      )}
      {renderTest()}
      {renderToast()}
    </Modal>
  );
};

const Title = ({ children }) => (
  <Typography.Title level={4} style={{ marginBottom: '1.5rem' }}>
    {children}
  </Typography.Title>
);
const GroupInputs = ({ children }) => (
  <Flex vertical gap="large">
    {children}
  </Flex>
);
