import { useEffect } from 'react';
import { useCampaign, useUpdateNode } from '../views/flow-designer/hooks';
import { LAYOUTS } from 'components/pages/campaigns/components/email/Layouts';

export const useUpdateBrandingSchool = ({ campaign, currentSchool, nodes }) => {
  const { getParamValue, setCampaignParam } = useCampaign();
  const { updateNodeData } = useUpdateNode();

  const {
    PRIMARY_COLOR_KEY,
    SECONDARY_COLOR_KEY,
    MEDIA_FACEBOOK_KEY,
    MEDIA_INSTAGRAM_KEY,
    MEDIA_TWITTER_KEY,
    MEDIA_YOUTUBE_KEY,
    SCHOOL_LOGO_KEY,
  } = LAYOUTS[0].keys;

  const updateNodeBrand = ({ primaryColor, secondaryColor, facebook, instagram, youtube, twitter, logo }) => {
    nodes.forEach((node) => {
      if (node.type === 'emailNode' || node.type === 'smsNode') {
        const updatedData = node.data.map((item) => {
          if (item.key === PRIMARY_COLOR_KEY) {
            return { ...item, value: primaryColor };
          }
          if (item.key === SECONDARY_COLOR_KEY) {
            return { ...item, value: secondaryColor };
          }
          if (item.key === MEDIA_FACEBOOK_KEY) {
            return { ...item, value: facebook };
          }
          if (item.key === MEDIA_INSTAGRAM_KEY) {
            return { ...item, value: instagram };
          }
          if (item.key === MEDIA_YOUTUBE_KEY) {
            return { ...item, value: youtube };
          }
          if (item.key === MEDIA_TWITTER_KEY) {
            return { ...item, value: twitter };
          }
          if (item.key === SCHOOL_LOGO_KEY) {
            return { ...item, value: logo };
          }
          return item;
        });
        updateNodeData(node.id, updatedData);
      }
    });
  };

  useEffect(() => {
    if (campaign && currentSchool) {
      const {
        primary_color,
        secondary_color,
        social_facebook,
        social_instagram,
        social_twitter,
        social_youtube,
        logo_image,
      } = currentSchool;

      const currentFacebook = getParamValue(MEDIA_FACEBOOK_KEY);
      const currentInstagram = getParamValue(MEDIA_INSTAGRAM_KEY);
      const currentYoutube = getParamValue(MEDIA_YOUTUBE_KEY);
      const currentTwitter = getParamValue(MEDIA_TWITTER_KEY);
      const currentLogo = getParamValue(SCHOOL_LOGO_KEY);

      const currentPrimaryColor = getParamValue(PRIMARY_COLOR_KEY);
      const currentSecondaryColor = getParamValue(SECONDARY_COLOR_KEY);

      const newPrimaryColor = primary_color || '#197f9c';
      const newSecondaryColor = secondary_color || '#ffffff';
      const newLogo = logo_image || currentLogo;

      if (
        newPrimaryColor !== currentPrimaryColor ||
        newSecondaryColor !== currentSecondaryColor ||
        social_facebook !== currentFacebook ||
        social_instagram !== currentInstagram ||
        social_twitter !== currentTwitter ||
        social_youtube !== currentYoutube ||
        newLogo !== currentLogo
      ) {
        setCampaignParam({ key: PRIMARY_COLOR_KEY, value: newPrimaryColor });
        setCampaignParam({ key: SECONDARY_COLOR_KEY, value: newSecondaryColor });
        setCampaignParam({ key: MEDIA_FACEBOOK_KEY, value: social_facebook });
        setCampaignParam({ key: MEDIA_INSTAGRAM_KEY, value: social_instagram });
        setCampaignParam({ key: MEDIA_TWITTER_KEY, value: social_twitter });
        setCampaignParam({ key: MEDIA_YOUTUBE_KEY, value: social_youtube });
        setCampaignParam({ key: SCHOOL_LOGO_KEY, value: newLogo });

        updateNodeBrand({
          primaryColor: newPrimaryColor,
          secondaryColor: newSecondaryColor,
          facebook: social_facebook,
          instagram: social_instagram,
          youtube: social_youtube,
          twitter: social_twitter,
          logo: newLogo,
        });
      }
    }
  }, [campaign, currentSchool]);
};
