import { useMemo } from 'react';
import { MinusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Selector } from 'components/pages/live-search/V2/selector';
import { singleValues } from './utils-options';
import { statusColor } from 'constants/status-color';
import { Button } from 'components/pages/communication-hub/components';
import { Divider, Typography } from 'antd';
import styles from './filter-value.module.scss';
import classNames from 'classnames';

export const FilterValue = ({
  filter,
  fields,
  idx,
  options,
  handleFilter,
  handleValues,
  removeFilter,
  onSelectAll,
  availableFields,
  isAdvancedCampaign,
}) => {
  const isStageField = useMemo(() => filter.field === 'status', []);

  const isMultiSelect = useMemo(() => !singleValues.includes(filter.field), [filter]);

  const getPlaceholderSelection = () => {
    if (!isMultiSelect) {
      return (
        <BooleanChip boolean={filter.values}>{options.find((option) => option.id === filter.values)?.name}</BooleanChip>
      );
    }

    if (filter.values?.length === 0) {
      return 'Select your options';
    }

    return (
      <span className={styles.selectionWrapper}>
        {filter.values?.map((value) => {
          const name = options?.find((option) => option.id === value)?.name;
          if (name) {
            return (
              <ChipItem
                key={value}
                onClick={() => handleValues(idx, { value })}
                style={isStageField ? { background: statusColor[value], color: '#fff' } : {}}>
                {name}
              </ChipItem>
            );
          }
        })}
      </span>
    );
  };

  const isStatusFieldFilterWrapperClasses = {
    [styles.filterWrapperIsStageField]: isStageField,
    [styles.filterWrapper]: !isStageField,
    [styles.filterWrapperAdvancedCampaign]: isAdvancedCampaign,
  };

  const filterClasses = {
    [styles.filterIsStageField]: isStageField,
    [styles.filter]: !isStageField,
    [styles.filterAdvancedCampaign]: isAdvancedCampaign,
  };

  return (
    <>
      {isStageField && <Typography.Title level={5}>Filters</Typography.Title>}
      <div className={classNames(isStatusFieldFilterWrapperClasses)}>
        <div
          className={classNames(
            filter.values ? [styles.isValuesShowed, filterClasses] : [styles.isNotValuesShowed, filterClasses]
          )}>
          {!isStageField && (
            <Selector
              id="fields"
              placeholder={fields.find((item) => filter.field === item.id)?.name || 'Select a field'}
              options={availableFields}
              currentSelected={filter?.id}
              onHandleChange={(value) => handleFilter(idx, { value: value.id })}
              className={styles.selector_fields}
            />
          )}
          {!isStageField ? (
            <Button
              danger
              color="danger"
              type="default"
              variant="outlined"
              htmlType="button"
              onClick={removeFilter}
              className={styles.filterWrapper_button}
              icon={<MinusCircleOutlined />}>
              {!isAdvancedCampaign ? 'Remove Filter' : null}
            </Button>
          ) : null}
          {filter.field && (
            <div className={isStageField ? styles.stage : styles.isNotStage}>
              {isStageField && (
                <div className={styles.stage_title}>
                  <span>Stage</span>
                </div>
              )}
              <Selector
                id="values"
                placeholder={getPlaceholderSelection()}
                options={options || []}
                onHandleChange={(value) => {
                  handleValues(idx, { value: value.id });
                }}
                currentSelected={filter.values}
                multiSelect={isMultiSelect}
                onSelectAll={onSelectAll}
                className={isStageField ? styles.selector_values_stage : styles.selector_values}
              />
            </div>
          )}
        </div>
        <Divider className="spacing-mb-16" />
      </div>
    </>
  );
};

const BooleanChip = ({ children, boolean }) => {
  return <ChipItem className={styles[boolean]}>{children}</ChipItem>;
};

const ChipItem = ({ children, className, onClick, ...props }) => {
  return (
    <span className={`${styles.item} ${className || ''}`} {...props}>
      {children}{' '}
      <button onClick={onClick}>
        <CloseCircleOutlined />
      </button>
    </span>
  );
};
