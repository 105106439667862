import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import styles from './campaign-filters.module.scss';
import { AntSelect, AntSelectItem } from '../..';

export const CampaignFilters = ({ children, typeFilter, statusFilter, setTypeFilter, setStatusFilter }) => {
  return (
    <Row className={styles.container} justify="space-between" align="middle">
      <Col>
        <Row gutter={16}>
          <Col>
            <AntSelect
              id="select-type"
              value={typeFilter}
              onChange={setTypeFilter}
              placeholder="Filter by type"
              className={styles.select}>
              <AntSelectItem value="all">All Types</AntSelectItem>
              <AntSelectItem value="email">Email</AntSelectItem>
              <AntSelectItem value="sms">SMS</AntSelectItem>
              <AntSelectItem value="hybrid">Hybrid</AntSelectItem>
            </AntSelect>
          </Col>
          <Col>
            <AntSelect
              id="select-status"
              value={statusFilter}
              onChange={setStatusFilter}
              placeholder="Filter by status"
              className={styles.select}>
              <AntSelectItem value="all">All Statuses</AntSelectItem>
              <AntSelectItem value="Active">Active</AntSelectItem>
              <AntSelectItem value="Draft">Draft</AntSelectItem>
              <AntSelectItem value="Finished">Finished</AntSelectItem>
              <AntSelectItem value="Paused">Paused</AntSelectItem>
              <AntSelectItem value="Archived">Archived</AntSelectItem>
            </AntSelect>
          </Col>
        </Row>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

CampaignFilters.propTypes = {
  typeFilter: PropTypes.string,
  statusFilter: PropTypes.string,
  setTypeFilter: PropTypes.func,
  setStatusFilter: PropTypes.func,
};
