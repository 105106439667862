import React from 'react';
import styles from './advanced-campaigns-summary.module.scss';

export const AdvancedCampaignsSummary = ({ leadsCount, summary, isTriggered, includeCurrentLeads }) => {
  const showLeads = !isTriggered || (isTriggered && includeCurrentLeads);

  return (
    <>
      <div className={styles.banner}>
        <div className={styles.section}>
          <h3 className={styles.title}>Campaign Trigger</h3>
          <p className={styles.description}>
            This campaign will trigger{' '}
            {isTriggered ? 'immediately when a new lead matches the selected criteria' : 'on the scheduled time'}.
          </p>
        </div>

        <div className={styles.section}>
          <h3 className={styles.title}>Initial Launch</h3>
          <p className={styles.description}>
            This campaign will start with{' '}
            {showLeads && (
              <>
                <span className={styles.semibold}>{leadsCount}</span> matching leads
              </>
            )}{' '}
            {isTriggered && includeCurrentLeads && ' and '}
            {isTriggered && 'new future matches.'}
          </p>
        </div>

        <div className={styles.section}>
          <h3 className={styles.title}>Campaign Flow</h3>
          <p className={styles.description}>{summary}</p>
        </div>
      </div>
      <p className={styles.message}>
        *Leads marked as Do Not Call, Duplicate, SPAM, or Wrong Contact Info are automatically deducted from your total
        Audience Size.
      </p>
    </>
  );
};
