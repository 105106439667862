import { useMemo, useState } from 'react';
import {
  MailOutlined,
  AppstoreOutlined,
  MessageOutlined,
  HistoryOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import { _addMarketingCampaign } from 'controllers/schools/schools_controller';
import { useStatus } from 'hooks/useStatus';
import { browserHistory } from 'react-router';
import campaingConstants from 'components/pages/campaigns/utils/CampaingConstants';
import dayjs from 'dayjs';
import _advancedTemplates from '../advanced-templates.json';

export const STAGES = {
  MODE: 'mode',
  PLATFORM: 'platform',
  RECURRENCE: 'recurrence',
  TEMPLATE: 'template',
};
const MODES = {
  SIMPLE: 'simple',
  ADVANCED: 'advanced',
};
const CAMPAIGN_PLATFORM = {
  EMAIL: 'email',
  SMS: 'sms',
  HYBRID: 'hybrid',
};

export const useCampaignSettings = ({ schoolId, defaultParams, templates }) => {
  const [stage, setStage] = useState(STAGES.MODE);

  const [settings, setSettings] = useState({
    mode: null,
    platform: null,
    recurrence: '',
    template: {},
  });

  const { status, onLoading, onSuccess, onError } = useStatus();

  const backToMode = () => {
    let backToStage;
    if (stage === STAGES.TEMPLATE || stage === STAGES.RECURRENCE) {
      backToStage = STAGES.PLATFORM;
    } else {
      backToStage = STAGES.MODE;
    }
    setStage(backToStage);
  };

  const handleMode = (mode) => {
    setStage(STAGES.PLATFORM);
    setSettings({ ...settings, mode });
  };

  const handleType = async (platform) => {
    setSettings({ ...settings, platform });

    if (settings.mode === MODES.SIMPLE) {
      setStage(STAGES.RECURRENCE);
    } else {
      setStage(STAGES.TEMPLATE);
    }
  };

  const advancedTemplates = useMemo(() => {
    const customTemplate = getCustomTemplate({ defaultParams, platform: settings.platform });
    const templates = _advancedTemplates.filter((template) => template.platform === settings.platform);

    return [customTemplate, ...templates];
  }, [settings.platform]);

  const handleRecurrence = (recurrence) => {
    setSettings({ ...settings, recurrence });

    setStage(STAGES.TEMPLATE);
  };

  const onTemplateSelect = async (template) => {
    setSettings({ ...settings, template });

    // payload for simple/legacy campaigns
    const campaignPayload = {
      name: `Untitled ${settings.mode} ${settings.platform} Campaign`,
      platform: settings.platform,
      type: settings.mode === MODES.SIMPLE ? settings.recurrence : campaingConstants.CAMPAIGN_TYPE_ADVANCED,
      layout_id: template.layout_id, // only for simple type
      marketing_template_id: template.id, // only for simple type
      audience_language: 'all',
      params: template.params || [],
    };
    onLoading();
    await createAndNavigate(
      schoolId,
      campaignPayload,
      settings.mode === MODES.SIMPLE ? `campaigns/dashboard` : `communication-hub/advanced`
    );
    onSuccess();
  };

  const titles = {
    [STAGES.MODE]: 'Choose Campaign Mode',
    [STAGES.PLATFORM]: `Choose ${settings.mode} Campaign Type`,
    [STAGES.RECURRENCE]: `Choose ${settings.platform} Campaign Recurrence`,
    [STAGES.TEMPLATE]: `Choose ${settings.platform} Template`,
  };

  const optionsModes = [
    {
      id: 'simple',
      onClick: () => handleMode(MODES.SIMPLE),
      label: 'Simple',
      icon: MailOutlined,
      description: 'Basic single-channel campaigns for straightforward communication',
      colorIcon: 'blue',
    },
    {
      id: 'advanced',
      onClick: () => handleMode(MODES.ADVANCED),
      label: 'Advanced',
      icon: AppstoreOutlined,
      description: 'Design multi-channel campaigns with advanced conditions and automated workflows',
      colorIcon: 'purple',
    },
  ];

  const optionsSimple = [
    {
      id: 'email',
      onClick: () => handleType(CAMPAIGN_PLATFORM.EMAIL),
      label: 'Email',
      icon: MailOutlined,
      description: 'Send a single email campaign',
      colorIcon: 'blue',
    },
    {
      id: 'sms',
      onClick: () => handleType(CAMPAIGN_PLATFORM.SMS),
      label: 'SMS',
      icon: MessageOutlined,
      description: 'Send a single SMS campaign',
      colorIcon: 'green',
    },
  ];

  const optionsRecurrence = [
    {
      id: 'one-time',
      onClick: () => handleRecurrence(campaingConstants.CAMPAIGN_TYPE_REGULAR_MASS),
      label: 'One Time',
      icon: NotificationOutlined,
      description: 'This is a bulk message sent to many contacts at once, only one time.',
      colorIcon: 'green',
    },
    {
      id: 'recurring',
      onClick: () => handleRecurrence(campaingConstants.CAMPAIGN_TYPE_RECURRING_AUTOMATED),
      label: 'Recurring',
      icon: HistoryOutlined,
      description: 'Messages send out automatically on a regular schedule based on predetermined rules or trigger.',
      colorIcon: 'blue',
    },
  ];

  const optionsAdvanced = [
    {
      id: 'email',
      onClick: () => handleType(CAMPAIGN_PLATFORM.EMAIL),
      label: 'Email',
      icon: MailOutlined,
      description: 'Advanced email workflows with automation and conditions',
      colorIcon: 'blue',
    },
    {
      id: 'sms',
      onClick: () => handleType(CAMPAIGN_PLATFORM.SMS),
      label: 'SMS',
      icon: MessageOutlined,
      description: 'Complex sequences with branching logic',
      colorIcon: 'green',
    },
    {
      id: 'hybrid',
      onClick: () => handleType(CAMPAIGN_PLATFORM.HYBRID),
      label: 'Hybrid',
      icon: AppstoreOutlined,
      description: 'Multi-channel campaigns combining emails and SMS',
      colorIcon: 'purple',
    },
  ];

  return {
    stage,
    settings,
    backToMode,
    onTemplateSelect,
    // options:
    optionsModes,
    optionsSimple,
    optionsAdvanced,
    optionsRecurrence,
    titles,
    advancedTemplates,
    status,
  };
};

const getCustomTemplate = ({ defaultParams, platform }) => {
  const segmentation = {
    key: campaingConstants.SEGMENTATION,
    value: '["status:new"]',
  };
  const defaultTrigger = campaingConstants.TRIGGER_ADD_SCHOLA_SYSTEM_VALUE;
  const trigger = { key: campaingConstants.TRIGGER_KEY, value: defaultTrigger };
  const defaultSchedule = {
    start_date: dayjs().format(campaingConstants.FORMAT_DATE),
    start_time: campaingConstants.DEFAULT_START_TIME,
    selectTimeZone: '(GMT-05:00) Eastern Time',
  };
  const schedule = {
    key: campaingConstants.SCHEDULE_KEY,
    value: JSON.stringify(defaultSchedule),
  };

  const isSMS = platform === 'sms';

  return {
    id: 0,
    name: `Custom Campaign`,
    iconKey: platform,
    platform,
    type: campaingConstants.CAMPAIGN_TYPE_ADVANCED,
    audience_language: 'all',
    layout_id: 1, // default value
    marketing_template_id: 1, // default value
    params: [
      // set global params
      ...(defaultParams || []),
      schedule,
      segmentation,
      trigger,
      { key: 'edges', value: JSON.stringify([]) },
      { key: 'viewport', value: JSON.stringify({ x: -175, y: 126.75, zoom: 1.5 }) },
      // each nodes contain a list of params
      { key: 'nodes', value: JSON.stringify([]) },
    ],
  };
};

const createAndNavigate = async (schoolId, campaignPayload, basePath) => {
  const response = await createCampaign(schoolId, campaignPayload);
  if (response && response.id) {
    window.location.href = `/admin/v2/schools/${schoolId}/${basePath}/${response.id}`;
  } else {
    console.error('Failed to create campaign');
  }
};

const createCampaign = async (schoolId, newCampaignParams) => {
  try {
    const newCampaign = await _addMarketingCampaign(schoolId, newCampaignParams);
    if (newCampaign && newCampaign.id) {
      return newCampaign;
    }

    throw new Error('Failed to create campaign');
  } catch (error) {
    console.error(error);
    return null;
  }
};
